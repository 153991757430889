import { DragEndEvent } from '@dnd-kit/core';
import { useContext } from 'react';
import { SnackbarContext, SnackbarVariant } from '@eas/common-web';
import { TECHNICIAN_TABLE } from '@modules/mobile-unit-switch/switcher';
import {
  createMUAssignmentOnDay,
  updateAssignment,
} from '@modules/mobile-unit/mobile-unit-api';
import { MobileUnitAssignment, ZsdUser } from '@models';
import { regionApiErrors } from '@enums';
import { handleApiError } from '@utils';

const rollback = async (assignment: MobileUnitAssignment | undefined) => {
  if (assignment) await updateAssignment(assignment);
};

export function useDragEnd({
  assignments,
  dataSources,
  date,
}: {
  assignments: MobileUnitAssignment[];
  dataSources: { reset: () => void }[];
  date: string;
}) {
  const { showSnackbar } = useContext(SnackbarContext);

  const showAssignmentNotFound = () => {
    showSnackbar(
      `Nepodarilo sa nájsť čatu na priradenie`,
      SnackbarVariant.ERROR
    );
  };

  const showUnsuccessfulUpdate = (error: any) => {
    handleApiError(error, regionApiErrors.MOBILE_UNIT_ASSIGNMENT, (m, v) =>
      showSnackbar('Nepodarilo sa priradiť technika:\n\n' + m, v)
    );
  };

  const resetDataSources = () => {
    dataSources.forEach((ds) => ds.reset());
  };

  return async (event: DragEndEvent) => {
    // TECHNICIAN_TABLE or 'leader:assignmentId' or 'other:assignmentId'
    const dragOrigin = event.active.data.current?.dragOrigin;
    delete event.active.data.current?.dragOrigin;

    const dragFrom = {
      id: dragOrigin,
      isTechnicianTable: dragOrigin === TECHNICIAN_TABLE,
      isLeader: dragOrigin?.split(':')[0] === 'leader',
      assignmentId: dragOrigin?.split(':')[2],
    };

    // TECHNICIAN_TABLE (for unassigning only) or 'leader:assignmentId' or 'other:assignmentId'
    const dragEnd = event.over?.id;
    if (typeof dragEnd !== 'string') return;

    const dragTo = {
      id: dragEnd,
      isTechnicianTable: dragEnd === TECHNICIAN_TABLE,
      isLeader: dragEnd?.split(':')[0] === 'leader',
      isExistingAssignment: dragEnd?.split(':')[1] === 'assignment',
      entityId: dragEnd?.split(':')[2],
    };

    const user = event.active.data.current as ZsdUser | undefined;

    // Not a valid drag (e.g. nowhere or same place)
    if (!dragFrom.id || !dragTo.id || dragFrom.id === dragTo.id || !user)
      return;

    // rollback in case of failure after first update
    let dragFromAssignmentRollback: MobileUnitAssignment | undefined;

    // Unassign technician from dragFrom
    if (!dragFrom.isTechnicianTable) {
      try {
        const dragFromAssignment = assignments.find(
          (a) => a.id === dragFrom.assignmentId
        );
        if (!dragFromAssignment) {
          showAssignmentNotFound();
          return;
        }

        // create backup
        dragFromAssignmentRollback = Object.assign({}, dragFromAssignment);
        dragFromAssignmentRollback.technicians = Array.from(
          dragFromAssignment.technicians
        );

        // update
        if (dragFromAssignment.leadTechnician?.id === user.id) {
          dragFromAssignment.leadTechnician = undefined;
        } else {
          dragFromAssignment.technicians =
            dragFromAssignment.technicians.filter((t) => t.id !== user.id);
        }
        const res = await updateAssignment(dragFromAssignment).json();
        if (res.error) throw res.error;
      } catch (error) {
        resetDataSources();
        showUnsuccessfulUpdate(error);
        return;
      }
    }

    // Assign technician to dragTo
    if (!dragTo.isTechnicianTable) {
      try {
        if (!dragTo.isExistingAssignment) {
          const newAssignment = await createMUAssignmentOnDay({
            leadTechnician: dragTo.isLeader ? user : undefined,
            technicians: dragTo.isLeader ? undefined : [user],
            mobileUnitId: { id: dragTo.entityId },
            date,
          }).json();
          if (newAssignment.error) throw newAssignment.error;
        } else {
          const dragToAssignment = assignments.find(
            (a) => a.id === dragTo.entityId
          );
          if (!dragToAssignment) {
            rollback(dragFromAssignmentRollback);
            showAssignmentNotFound();
            return;
          }

          if (dragTo.isLeader) {
            dragToAssignment.leadTechnician = user;
          } else {
            dragToAssignment.technicians.push(user);
          }

          const res = await updateAssignment(dragToAssignment).json();
          if (res.error) throw res.error;
        }
      } catch (error) {
        rollback(dragFromAssignmentRollback);
        resetDataSources();
        showUnsuccessfulUpdate(error);
        return;
      }
    }

    showSnackbar('Technik bol úspešne presunutý', SnackbarVariant.SUCCESS);

    resetDataSources();
  };
}
