import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DictionaryEvidence,
  FormPanel,
  FormTableField,
  FormTextField,
} from '@eas/common-web';

export function Fields() {
  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__TECHNICAL_OBJECT_TYPE__DETAIL__PANEL__GENERAL"
            defaultMessage="Všeobecné"
          />
        }
      >
        <DictionaryEvidence.FieldName />
        <FormTextField
          name="voltageLevel.name"
          label={
            <FormattedMessage
              id="ZSD__TECHNICAL_OBJECT_TYPE__DETAIL__FIELD__VOLTAGE_LEVEL"
              defaultMessage="Napätie"
            />
          }
        />
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__TECHNICAL_OBJECT_TYPE__DETAIL__PANEL__ATTRIBUTES"
            defaultMessage="Typy atribútov"
          />
        }
        expandable={false}
        defaultExpanded={true}
      >
        <FormTableField
          name="attributeTypes"
          disabled={true}
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
          showDetailBtnCond={() => false}
          showToolbar={false}
          columns={[
            {
              datakey: 'name',
              name: 'Názov',
              width: 600,
            },
          ]}
        />
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__TECHNICAL_OBJECT_TYPE__DETAIL__PANEL__COMPONENTS"
            defaultMessage="Typy komponent"
          />
        }
        expandable={false}
        defaultExpanded={true}
      >
        <FormTableField
          name="componentTypes"
          disabled={true}
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
          showDetailBtnCond={() => false}
          showToolbar={false}
          columns={[
            {
              datakey: 'name',
              name: 'Názov',
              width: 600,
            },
          ]}
        />
      </FormPanel>
    </>
  );
}
