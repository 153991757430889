import { TechnicalObjectOracle } from 'src/models/technical-object';
import { GPSPoint } from '@models';

export const getTosWithingRange = async (coordinates: GPSPoint) => {
  const response = await fetch(
    `/api/zsd/technical-object/list/within-radius?longitude=${coordinates.longitude}&latitude=${coordinates.latitude}&radius=500`
  );

  return (await response.json()) as TechnicalObjectOracle[];
};
