import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    margin: 10,
  },
  title: {
    fontSize: 25,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  selectTeamText: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  errorTitle: {
    color: theme.palette.error.main,
    fontSize: 20,
    textAlign: 'center',
    padding: 20,
  },
  missingSelectedTitle: {
    fontSize: 16,
    textAlign: 'center',
    padding: '60px 0',
  },
  controlsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 10,
  },
  controlsRight: {
    display: 'flex',
    gap: '10px',
  },
  contentWrapper: {
    display: 'flex',
    borderBottom: '1px solid #ccc',
    borderTop: '1px solid #ccc',
    borderLeft: '1px solid #ccc',
    borderRight: '1px solid #ccc',
    margin: '0 10px',
    alignItems: 'start',
    backgroundColor: '#e8e8e8',
    overflowX: 'auto',
    overflowY: 'hidden',
    minHeight: '300px',
  },
  box: {
    textAlign: 'center',
    gap: '10px',
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px solid #ccc',
    backgroundColor: '#fff',
    width: '350px',
    height: '100%',
  },
  boxHeadings: {
    textAlign: 'left',
    padding: 10,
  },
  boxTitleRow: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  boxTitle: {
    fontWeight: 500,
    fontSize: 20,
    whiteSpace: 'nowrap',
    textDecoration: 'none',
  },
  boxSubTitle: {
    fontWeight: 500,
    fontSize: 12,
    paddingTop: 10,
    whiteSpace: 'break-spaces',
  },
  boxContent: {
    textAlign: 'left',
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  boxContentTitle: {
    fontWeight: 500,
    padding: '5px 10px',
  },
  boxErrors: {
    padding: '5px 12px',
    height: '90px',
    overflowY: 'auto',
    backgroundColor: '#f5f5f5',
    color: theme.palette.error.main,
    lineHeight: 1.5,
  },
  boxErrorsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
  },
  boxErrorsTitle: {
    padding: '2px 0',
    fontWeight: 500,
  },
  droppable: {
    border: '1px solid transparent',
  },
  droppableOther: {
    flexGrow: 1,
    overflowY: 'auto',
    height: '140px',
  },
  icon: {
    color: theme.palette.error.main,
    width: '20px',
    height: '20px',
  },

  // styles from planner (calendar)
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  bottomWindow: {
    margin: '0 10px',
    flex: 2,
    flexBasis: '100%',
    height: '50%',
    border: '1px solid transparent',
  },
  button: {
    borderRadius: 4,
    textTransform: 'none',
  },
  basicButton: {
    color: theme.palette.getContrastText(theme.palette.grey[300]),
    borderColor: theme.palette.grey[300],
    backgroundColor: '#fff',
  },
  outlineButton: {
    color: theme.palette.tertiary.main,
    borderColor: theme.palette.tertiary.main,
  },
  opacity50: {
    opacity: 0.5,
  },
}));

export const useTechnicianTableStyles = makeStyles((theme) => ({
  icon: {
    color: '#cfcdcd',
    width: '20px',
    height: '20px',
  },
  warning: {
    color: theme.palette.error.main,
  },
  dndTechnicianRoot: {
    width: 250,
    height: 50,
    position: 'relative',
  },
  dndTechnicianWrapper: {
    width: 250,
    height: 50,
    position: 'absolute',
  },
  dndTechnician: {
    height: 30,
    padding: '0 16px',
    background: 'white',
    boxShadow: theme.shadows[20],
    cursor: 'grabbing',
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
  },
  dndTechnicianTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.grey[700],
  },
  dndTechnicianIcon: {
    color: theme.palette.grey[700],
    fontSize: 18,
  },
  menubarTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    overflow: 'hidden',
    position: 'relative',
    height: '100%',
  },
  tableGroupWrapper: {
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  searchBar: {
    width: '100%',
    display: 'flex',
  },
  searchWrapper: {
    flex: '0 0 auto',
    padding: '10px 0',
    borderRadius: '10px 10px 0 0',
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  searchTextField: {
    width: '100%',
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 0,
    marginTop: 0,
    backgroundColor: '#fff',

    '& > div': {
      fontSize: 14,
      '& input[type="search"]::-webkit-search-cancel-button': {
        display: 'none',
      },
    },
  },
  input: {
    backgroundColor: 'transparent',
    zIndex: 10,
    height: 41,
  },
  searchTextFieldInput: {
    boxShadow: 'none',
    paddingRight: 0,
    height: 41,
    '&:hover': {
      borderColor: 'red',
    },
  },
  list: {
    width: 300,
    listStyleType: 'none',
    backgroundColor: theme.palette.grey[200],
    border: `1px solid ${theme.palette.grey[300]}`,
    position: 'absolute',
    fontFamily: 'monospace',
    fontSize: 14,
  },
  listItem: {
    fontWeight: 500,
    '&:focus': {
      background: '#fffacf',
    },
  },
  smallFilterIcon: {
    cursor: 'pointer',
    fontSize: 22,
    marginRight: 5,
    color: theme.palette.grey[500],
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  filterIcon: {
    cursor: 'pointer',
    marginRight: 5,
    color: theme.palette.grey[500],
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  activeFilterIcon: {
    cursor: 'pointer',
    marginRight: 5,
    color: theme.palette.primary.dark,
    backgroundColor: `${theme.palette.primary.light}40`,
  },
  searchIcon: {
    cursor: 'pointer',
    padding: 6,
    background: theme.palette.primary.main,
    color: 'white',
    width: 36,
    height: 39,
    marginRight: 1,

    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
  toolbarWrapper: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    paddingTop: 5,
    paddingRight: 0,
    paddingLeft: 0,
    paddingBottom: 5,
    justifyContent: 'space-between',
    height: 38,
    boxSizing: 'border-box',
  },
  toolbarSelected: {
    backgroundColor: theme.palette.primary.main,
  },
  toolbarText: {
    textAlign: 'left',
    fontWeight: 'bold',
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  toolbarTextSub: {
    fontSize: '1.1em !important',
    background: `${theme.palette.primary.light}40`,
    width: '20px',
    lineHeight: 'inherit',
    textAlign: 'center',
    marginTop: -2,
    marginBottom: -2,
    marginRight: -3,
  },
  toolbarSelectedLabel: {
    color: theme.palette.primary.contrastText,
    width: '100%',
    display: 'flex',

    alignItems: 'center',

    fontWeight: 'bold',
    justifyContent: 'space-between',
    borderRadius: 2,
    whiteSpace: 'nowrap',

    '& svg': {
      fontSize: '1rem',
      cursor: 'pointer',
    },
  },
  toolbarButtonList: {
    marginTop: 0,
    marginBottom: 0,
    marginRight: 10,
    listStyle: 'none',
    display: 'flex',
  },
  toolbarButtonWrapper: {
    marginLeft: 5,
  },
  toolbarButton: {
    height: 25,
    minWidth: 40,
    fontSize: '14px!important',
    cursor: 'pointer',
    padding: '2px 5px',

    '&.MuiButton-contained:not(.MuiButton-containedPrimary):not(.MuiButton-containedSecondary)':
      {
        backgroundColor: '#f1f3f4',
        '&:hover': {
          backgroundColor: '#e0e2e3',
        },
      },
  },
  dialogTitleHeader: {
    fontWeight: 700,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  dialogActions: {
    padding: '10px 24px',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
  dialogButtonLabel: {
    textTransform: 'capitalize',
  },
  columnDialogList: {
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    paddingLeft: 0,
  },
  columnDialogItem: {
    minWidth: 350,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'rgb(241, 243, 244)',
    },
    zIndex: 10999,
  },
  columnDialogItemLabel: {
    flex: '1 1 auto',
    height: 30,
  },
  columnDialogItemDraggable: {
    flex: '0 0 auto',
  },
  filterDialogColumnsWrapper: {
    maxWidth: 900,
  },
  filterDialogColumn: {
    display: 'flex',

    '@media (max-width:960px)': {
      flexDirection: 'column',
    },
  },
  filterDialogItem: {
    width: 290,
    padding: '0 5px',
    position: 'relative',
    boxSizing: 'border-box',

    '@media (max-width:960px)': {
      width: '100%',
    },
  },
  filterDialogItemSubWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 5,
  },
  filterDialogItemValueWrapper: {
    width: 200,
  },
  dialogCheckBoxLabel: {
    fontSize: 14,
  },
  tableWrapper: {
    overflowX: 'scroll',
    minWidth: '100%',
    maxWidth: '100%',

    '&:focus': {
      outline: 0,
    },
  },
  header: {
    height: 50,
    boxSizing: 'border-box',
    width: 'fit-content',
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'row',
    minWidth: '100%',
    color: 'black',
    backgroundColor: '#e9e9e9',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    padding: '0 12px',
  },
  tableRowActions: {
    flexShrink: 0,
    width: 35,
    verticalAlign: 'top',
    display: 'flex',
    alignItems: 'center',
    padding: 0,

    '& svg': {
      width: 18,
    },
  },
  tableRowHeader: {
    flexShrink: 0,
    display: 'flex',
    lineHeight: '50px',
    alignItems: 'center',
  },
  tableRowHeaderLabel: {
    display: 'inline-block',
    flex: '1 1 auto',
    overflow: 'hidden',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    margin: 'auto',
    textTransform: 'uppercase',
  },
  draggable: {},
  draggableIcon: {
    width: 10,
    cursor: 'col-resize',
  },
  headerCellDraggable: {
    marginLeft: 5,
    marginRight: 5,
  },
  dataWrapper: {
    minHeight: 100,
    minWidth: '100%',
    width: 'fit-content',
  },
  scrollContainer: {
    overflowY: 'scroll !important' as 'scroll',
    '& > div': {
      direction: 'ltr',
    },
  },
  row: {
    height: 30,
    boxSizing: 'border-box',
    borderBottom: '1px solid rgb(232, 234, 237)',
    minWidth: '100%',
    '&:hover': {
      backgroundColor: `${theme.palette.highlight} !important`,
    },
    padding: '2px 12px',
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    fontSize: 14,
    fontWeight: 500,
  },
  rowActive: {
    backgroundColor: '#f6f3f3 !important',
    boxShadow: `inset 5px 0px 0 0 ${theme.palette.primary.main}`,
  },
  rowSimple: {
    gap: '10px',
    whiteSpace: 'nowrap',
    minWidth: '300px',
    borderBottom: 'none',
  },
  rowLeader: {
    backgroundColor: '#f5f5f5',
  },
  rowEmpty: {
    '&:hover': {
      backgroundColor: '#f5f5f5 !important',
    },
  },
  tableCell: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'inline-block',
    verticalAlign: 'top',

    '& input': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  tableCellFlex: {
    display: 'flex',
  },
  cellWrapper: {
    flexShrink: 0,
    display: 'flex',
    paddingRight: 10,
    boxSizing: 'border-box',
  },
  iconRotate: {
    transform: 'scaleY(-1)',
  },
  sortable: {
    cursor: 'pointer',
  },
  sortSup: {
    fontWeight: 400,
    alignSelf: 'flex-start',
    marginTop: 5,
    marginLeft: -5,
    fontSize: 12,
  },
  columnAlignLeft: {
    justifyContent: 'flex-start !important',
  },
  columnAlignRight: {
    justifyContent: 'flex-end !important',
    paddingRight: 15,
  },
  columnAlignCenter: {
    justifyContent: 'center !important',
  },
  progress: {
    position: 'absolute',
    width: '100%',
  },
  noMatch: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    margin: '15px 0',
    zIndex: 99,

    '& > svg': {
      margin: 30,
    },

    '& > a': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      cursor: 'pointer',
    },
  },
  buttonLabel: {
    textTransform: 'uppercase',
  },
}));
