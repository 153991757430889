import dayjs from 'dayjs';
import { useContext } from 'react';
import {
  PromptContext,
  ScrollableSource,
  SnackbarContext,
  SnackbarVariant,
} from '@eas/common-web';
import { completePlan } from '@modules/work-order/work-order-api';
import { WorkOrder, ZsdUser } from '@models';
import { CalendarDataSourceType } from '../calendar/calendar-types';

export function useAutoPlannerSubmit({
  workOrderSource,
  calendarSource,
}: {
  workOrderSource: ScrollableSource<WorkOrder>;
  calendarSource: CalendarDataSourceType;
}) {
  const { testPrompt } = useContext(PromptContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const handleClick = () =>
    testPrompt({
      key: 'WORK_ORDER_AUTOPLANNER_DIALOG',
      submitCallback: async (values) => {
        const body = {
          calendarOwners: [
            ...(calendarSource.unitsSource.selected ?? []).map((unit) => ({
              id: unit.id,
              type: ((unit as ZsdUser).type === 'TECHNICIAN'
                ? 'TECHNICIAN'
                : 'MOBILE_UNIT') as 'TECHNICIAN' | 'MOBILE_UNIT',
            })),
          ],
          from: dayjs.unix(values.from).format('YYYY-MM-DD'),
          to: dayjs.unix(values.to).format('YYYY-MM-DD'),
        };

        try {
          await completePlan(body).raw();

          workOrderSource.reset();
          calendarSource.yAxis.refreshData();
        } catch (error) {
          showSnackbar(
            'Pracový príkaz sa nepodarilo naplánovať. \n' +
              (ERROR_CODES?.[(error as any)?.code] ??
                `\n ${JSON.stringify(error, null, 2)}`),
            SnackbarVariant.ERROR
          );
        }
      },
    });

  return { handleClick };
}

const ERROR_CODES: Record<string, string> = {
  MAXIMUM_NUMBER_OF_DAYS_EXCEEDED: 'Prekročený maximálny počet dní (3).',
};
