import { useContext } from 'react';
import { UserSettingsContext, usePrompts } from '@eas/common-web';
import { CalendarDimensions } from '@enums';
import { CalendarHandle } from '../calendar/calendar-types';
import { SettingsForm } from './settings-form';
import { useSettingsSubmit } from './settings-hook';

export const settingsVersion = 1;
export const settingsId = 'CALENDAR_SETTINGS';

export const defualtSettings: Partial<CalendarSettings> = {
  dimension: CalendarDimensions.WEEK,
  rowHeight: 40,
  headerHeight: 75,
  legendWidth: 200,
  stepWidth: 50,
  startingHour: 6,
  endingHour: 16,
  colorForShiftV: '#D9D9D940',
  colorForShiftZ: '#ffffff',
  colorForShiftZP: '#e2edfd',
  colorForShiftA: '#bfe3c3',
  colorForShiftP: '#f9dddf',
  pinnedMap: false,
  groupToMultitaskCount: 15,
};

export type CalendarSettings = {
  version: number;
  dimension: CalendarDimensions;
  rowHeight: number;
  headerHeight: number;
  legendWidth: number;
  stepWidth: number;
  startingHour: number;
  endingHour: number;
  colorForShiftV: string;
  colorForShiftZ: string;
  colorForShiftZP: string;
  colorForShiftA: string;
  colorForShiftP: string;
  pinnedMap?: boolean;
  groupToMultitaskCount: number;
};

export function useSettings({
  calendarRef,
}: {
  calendarRef: React.RefObject<CalendarHandle>;
}) {
  const { getCustomSettings } = useContext(UserSettingsContext);

  const savedSettings = getCustomSettings(
    settingsId,
    settingsVersion
  ) as CalendarSettings;

  usePrompts(
    [
      {
        key: 'PLANNER_SETTINGS_DIALOG',
        dialogTitle: 'Nastavenia',
        dialogShowClose: true,
        dialogShowConfirm: true,
        dialogWidth: 700,
        dialogText: '',
        formInitialValues: {
          ...defualtSettings,
          ...savedSettings,
        },
        FormFields: SettingsForm,
      },
    ],
    [savedSettings]
  );

  const { handleClick } = useSettingsSubmit({
    calendarRef,
  });

  return { handleClick };
}
