import React from 'react';
import { useIntl } from 'react-intl';
import { DictionaryEvidence, TableCells, TableColumn } from '@eas/common-web';
import { DefectType } from '@models';

export function useColumns(): TableColumn<DefectType>[] {
  const intl = useIntl();

  const dictionaryColumns =
    DictionaryEvidence.useDictionaryColumns<DefectType>();

  return [
    dictionaryColumns.columnName,
    {
      datakey: 'technicalObjectType',
      sortkey: 'technicalObjectType.name',
      filterkey: 'technicalObjectType.id',
      name: intl.formatMessage({
        id: 'ZSD__DEFECT_TYPE__TABLE__COLUMN__TECHNICAL_OBJECT_TYPE',
        defaultMessage: 'Typ technického objektu',
      }),
      sortable: true,
      filterable: true,
      width: 250,
      CellComponent: function TypeCell({ value, ...props }) {
        return <TableCells.TextCell value={value?.name} {...props} />;
      },
    },
    {
      datakey: 'severity',
      sortkey: 'severity',
      filterkey: 'severity',
      name: intl.formatMessage({
        id: 'ZSD__DEFECT_TYPE__TABLE__COLUMN__SEVERITY',
        defaultMessage: 'Závažnosť',
      }),
      sortable: true,
      filterable: true,
      width: 100,
      CellComponent: TableCells.NumberCell,
    },
    {
      datakey: 'category',
      sortkey: 'category.name',
      filterkey: 'category.id',
      name: intl.formatMessage({
        id: 'ZSD__DEFECT_TYPE__TABLE__COLUMN__CATEGORY',
        defaultMessage: 'Typ kontrolného bodu',
      }),
      sortable: true,
      filterable: true,
      width: 200,
      CellComponent: function CategoryCell({ value, ...props }) {
        return <TableCells.TextCell value={value?.name} {...props} />;
      },
    },
  ];
}
