import React from 'react';
import { WorkOrderDynamicAttribute } from 'src/models/work-order-dynamic-attribute';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import { usePrompts } from '@eas/common-web';
import { WorkOrderState } from '@models';

const dynamicAttributesToFormFields = (
  dynamicAttributes: WorkOrderDynamicAttribute[]
) => {
  const formFieldObject = {} as any;
  dynamicAttributes
    .filter((attr) => !attr.attributeName?.deleted)
    .forEach((attr) => {
      const name = attr.attributeName.name ?? '';
      const value =
        attr.stringValue ||
        attr.intValue ||
        attr.booleanValue ||
        attr.dateTimeValue ||
        null;
      formFieldObject[name] = value;
    });
  return formFieldObject;
};

export const mergeAttributes = (attributes: any[]) => {
  const [staticAttributes, dynamicAttributes] = attributes;
  return {
    ...staticAttributes,
    dynamicAttributes: dynamicAttributesToFormFields(dynamicAttributes),
  };
};

export const useWorkOrderStateColumn = (value: WorkOrderState) => {
  const color = value.color;
  const theme = useTheme();

  return (
    <div
      style={{
        backgroundColor: color,
      }}
    >
      <Typography
        variant="body2"
        style={{
          padding: '0 4px',
          fontWeight: 400,
          whiteSpace: 'nowrap',
          color: theme.palette.getContrastText(color),
        }}
      >
        {value.name}
      </Typography>
    </div>
  );
};

export const useRegisterUnplanWarningPrompt = () =>
  usePrompts(
    [
      {
        key: 'MULTITASK_UNPLAN_ALERT',
        dialogTitle: 'Odplánovať pracovné príkazy',
        dialogShowClose: true,
        dialogShowConfirm: true,
        dialogWidth: 400,
        dialogText: (
          <div>
            <b>Upozornenie</b>:<br /> Odplánované budú iba <b>odomknuté</b>{' '}
            pracovné príkazy v stave{' '}
            <b>naplánovaná, odložená alebo odmietnutá</b>
            . <br /> Naozaj chcete odplánovať označené pracovné príkazy?
          </div>
        ),
      },
    ],
    []
  );
