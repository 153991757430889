import React, { useContext } from 'react';
import { forwardRef, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import {
  PromptContext,
  SnackbarContext,
  SnackbarVariant,
  abortableFetch,
} from '@eas/common-web';
import {
  unplanWorkOrder,
  updateWorkOrder,
} from '@modules/work-order/work-order-api';
import { WorkOrder } from '@models';
import { EvidenceApiUrl } from '@enums';

type MenuItemProps = {
  closeMenu: () => void;
  workOrder: WorkOrder;
  afterSubmit: () => void;
};

export const useHandleLockWorkOrder = (
  workOrder: WorkOrder,
  afterSubmit: () => void,
  closeMenu: () => void,
  forceLockState: 'lock' | 'unlock' | undefined = undefined
) =>
  useCallback(async () => {
    // fetch the latest data
    const latest = (await abortableFetch(
      `${EvidenceApiUrl.WORK_ORDERS}/${workOrder.id}`
    ).json()) as WorkOrder;

    if (forceLockState) {
      await updateWorkOrder(workOrder.id, {
        ...latest,
        locked: forceLockState === 'lock',
      }).json();
    } else {
      if (latest && latest.locked === workOrder.locked) {
        await updateWorkOrder(workOrder.id, {
          ...latest,
          locked: !workOrder.locked,
        }).json();
      }
    }

    afterSubmit();

    closeMenu();
  }, [workOrder.id, workOrder.locked, closeMenu, afterSubmit, forceLockState]);

function hasState(codes: string[], currentState: string | undefined) {
  return codes.includes(currentState || '');
}

export const UnplanWorkOrderMenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
  function UnplanWorkOrderMenuItem({ closeMenu, workOrder, afterSubmit }, ref) {
    const { testPrompt } = useContext(PromptContext);
    const { showSnackbar } = useContext(SnackbarContext);
    const intl = useIntl();

    const unplan = useCallback(async () => {
      try {
        await unplanWorkOrder(workOrder.id).json();
        //TODO: asi by sa tu mali tiez dopocitat prejazdy, ako pri zmene stavu, viz zsd-web\src\modules\planner\work-order-menu\menu-items\state.tsx
      } catch (error) {
        showSnackbar(
          intl.formatMessage({
            id: 'WORK_ORDER_CHANGE_STATE_ERROR',
            defaultMessage: 'Nepodarilo sa zmeniť stav pracovného príkazu',
          }),
          SnackbarVariant.ERROR
        );
      }

      closeMenu();
      afterSubmit();
    }, [closeMenu, afterSubmit, workOrder.id, showSnackbar, intl]);

    if (!hasState(['NAP', 'ODL', 'ODM'], workOrder.currentState?.code)) {
      return null;
    }

    return (
      <MenuItem
        disabled={!workOrder.id || workOrder.locked}
        ref={ref}
        onClick={() => {
          if (workOrder.id) {
            testPrompt({
              key: `WORK_ORDER_UNPLAN_ALERT`,
              submitCallback: unplan,
            });
          }
        }}
      >
        <ListItemIcon>
          <EventBusyIcon fontSize="small" />
        </ListItemIcon>

        <FormattedMessage
          id="WORK_ORDER_MENU_ITEM_UNPLAN"
          defaultMessage="Odplánovať"
        />
      </MenuItem>
    );
  }
);
