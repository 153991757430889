/**
 * Oprávnění
 */
export enum Permission {
  /**
   * Čítanie úsekov
   */
  SECTION_READ = 'SECTION_READ',

  /**
   * Spravovanie úsekov
   */
  SECTION_WRITE = 'SECTION_WRITE',

  /**
   * Vyhľadávanie a filtrovanie úsekov
   */
  SECTION_LIST = 'SECTION_LIST',

  /**
   * Čítanie tímov
   */
  TEAM_READ = 'TEAM_READ',

  /**
   * Spravovanie tímov
   */
  TEAM_WRITE = 'TEAM_WRITE',

  /**
   * Vyhľadávanie a filtrovanie tímov
   */
  TEAM_LIST = 'TEAM_LIST',

  /**
   * Čítanie pracovísk
   */
  DEPARTMENT_READ = 'DEPARTMENT_READ',

  /**
   * Spravovanie pracovísk
   */
  DEPARTMENT_WRITE = 'DEPARTMENT_WRITE',

  /**
   * Vyhľadávanie a filtrovanie pracovísk
   */
  DEPARTMENT_LIST = 'DEPARTMENT_LIST',

  /**
   * Čítanie skupin oprávnení
   */
  ROLE_READ = 'ROLE_READ',

  /**
   * Spravovanie skupin oprávnení
   */
  ROLE_WRITE = 'ROLE_WRITE',

  /**
   * Vyhľadávanie a filtrovanie skupin oprávnení
   */
  ROLE_LIST = 'ROLE_LIST',

  /**
   * Vyhľadávanie a filtrovanie oprávnení
   */
  PERMISSION_LIST = 'PERMISSION_LIST',

  /**
   * Čítanie používateľov
   */
  USER_READ = 'USER_READ',

  /**
   * Spravovanie používateľov
   */
  USER_WRITE = 'USER_WRITE',

  /**
   * Vyhľadávanie a filtrovanie používateľov
   */
  USER_LIST = 'USER_LIST',

  /**
   * Čítanie mobilných jednotek
   */
  MOBILE_UNIT_READ = 'MOBILE_UNIT_READ',

  /**
   * Spravovanie mobilných jednotek
   */
  MOBILE_UNIT_WRITE = 'MOBILE_UNIT_WRITE',

  /**
   * Vyhľadávanie a filtrovanie mobilných jednotek
   */
  MOBILE_UNIT_LIST = 'MOBILE_UNIT_LIST',

  /**
   * Čítanie pracovných prikazov
   */
  WORK_ORDER_READ = 'WORK_ORDER_READ',

  /**
   * Spravovanie pracovných prikazov
   */
  WORK_ORDER_WRITE = 'WORK_ORDER_WRITE',

  /**
   * Vyhľadávanie a filtrovanie pracovných prikazov
   */
  WORK_ORDER_LIST = 'WORK_ORDER_LIST',

  /**
   * Čítanie kalendárových šablón
   */
  CALENDAR_TEMPLATE_READ = 'CALENDAR_TEMPLATE_READ',

  /**
   * Spravovanie kalendárových šablón
   */
  CALENDAR_TEMPLATE_WRITE = 'CALENDAR_TEMPLATE_WRITE',

  /**
   * Vyhľadávanie a filtrovanie kalendárových šablón
   */
  CALENDAR_TEMPLATE_LIST = 'CALENDAR_TEMPLATE_LIST',

  /**
   * Čítanie pridených kalendárových
   */
  CALENDAR_READ = 'CALENDAR_READ',

  /**
   * Spravovanie pridených kalendárových
   */
  CALENDAR_WRITE = 'CALENDAR_WRITE',

  /**
   * Vyhľadávanie a filtrovanie pridených kalendárových
   */
  CALENDAR_LIST = 'CALENDAR_LIST',

  /**
   * Čítanie pracovných zmien
   */
  SHIFT_READ = 'SHIFT_READ',

  /**
   * Spravovanie pracovných zmien
   */
  SHIFT_WRITE = 'SHIFT_WRITE',

  /**
   * Vyhľadávanie a filtrovanie pracovných zmien
   */
  SHIFT_LIST = 'SHIFT_LIST',

  /**
   * Čítanie kalendárových výnimiek
   */
  CALENDAR_EXCEPTION_READ = 'CALENDAR_EXCEPTION_READ',

  /**
   * Spravovanie kalendárových výnimiek
   */
  CALENDAR_EXCEPTION_WRITE = 'CALENDAR_EXCEPTION_WRITE',

  /**
   * Vyhľadávanie a filtrovanie kalendárových výnimiek
   */
  CALENDAR_EXCEPTION_LIST = 'CALENDAR_EXCEPTION_LIST',

  /**
   * Čítanie certifikácií
   */
  CERTIFICATION_READ = 'CERTIFICATION_READ',

  /**
   * Spravovanie certifikácií
   */
  CERTIFICATION_WRITE = 'CERTIFICATION_WRITE',

  /**
   * Vyhľadávanie a filtrovanie certifikácií
   */
  CERTIFICATION_LIST = 'CERTIFICATION_LIST',

  /**
   * Čítanie sviatkov
   */
  HOLIDAY_READ = 'HOLIDAY_READ',

  /**
   * Spravovanie sviatkov
   */
  HOLIDAY_WRITE = 'HOLIDAY_WRITE',

  /**
   * Vyhľadávanie a filtrovanie sviatkov
   */
  HOLIDAY_LIST = 'HOLIDAY_LIST',

  /**
   * Čítanie absencií
   */
  ABSENCE_READ = 'ABSENCE_READ',

  /**
   * Spravovanie absencií
   */
  ABSENCE_WRITE = 'ABSENCE_WRITE',

  /**
   * Vyhľadávanie a filtrovanie absencií
   */
  ABSENCE_LIST = 'ABSENCE_LIST',

  /**
   * Čítanie typů absencií
   */
  ABSENCE_TYPE_READ = 'ABSENCE_TYPE_READ',

  /**
   * Spravovanie typů absencií
   */
  ABSENCE_TYPE_WRITE = 'ABSENCE_TYPE_WRITE',

  /**
   * Vyhľadávanie a filtrovanie typů absencií
   */
  ABSENCE_TYPE_LIST = 'ABSENCE_TYPE_LIST',

  /**
   * Spravovanie vlastných absencií
   */
  ABSENCE_WRITE_OWN = 'ABSENCE_WRITE_OWN',

  /**
   * Schválenie absencie
   */
  ABSENCE_ALLOW = 'ABSENCE_ALLOW',

  /**
   * Čítanie číselníku Druh PP
   */
  WORK_ORDER_TYPE_READ = 'WORK_ORDER_TYPE_READ',

  /**
   * Spravovanie číselníku Druh PP
   */
  WORK_ORDER_TYPE_WRITE = 'WORK_ORDER_TYPE_WRITE',

  /**
   * Vyhľadávanie a filtrovanie číselníku Druh PP
   */
  WORK_ORDER_TYPE_LIST = 'WORK_ORDER_TYPE_LIST',

  /**
   * Čítanie číselníku Stav PP
   */
  WORK_ORDER_STATE_READ = 'WORK_ORDER_STATE_READ',

  /**
   * Spravovanie číselníku Stav PP
   */
  WORK_ORDER_STATE_WRITE = 'WORK_ORDER_STATE_WRITE',

  /**
   * Vyhľadávanie a filtrovanie číselníku Stav PP
   */
  WORK_ORDER_STATE_LIST = 'WORK_ORDER_STATE_LIST',

  /**
   * Čítanie názvov dynamických atribútov pracovných prikazov
   */
  WORK_ORDER_DYNAMIC_ATTRIBUTE_NAME_READ = 'WORK_ORDER_DYNAMIC_ATTRIBUTE_NAME_READ',

  /**
   * Spravovanie názvov dynamických atribútov pracovných prikazov
   */
  WORK_ORDER_DYNAMIC_ATTRIBUTE_NAME_WRITE = 'WORK_ORDER_DYNAMIC_ATTRIBUTE_NAME_WRITE',

  /**
   * Vyhľadávanie a filtrovanie názvov dynamických atribútov pracovných prikazov
   */
  WORK_ORDER_DYNAMIC_ATTRIBUTE_NAME_LIST = 'WORK_ORDER_DYNAMIC_ATTRIBUTE_NAME_LIST',

  /**
   * Čítanie formulárových šablón
   */
  FORM_TEMPLATE_READ = 'FORM_TEMPLATE_READ',

  /**
   * Spravovanie formulárových šablón
   */
  FORM_TEMPLATE_WRITE = 'FORM_TEMPLATE_WRITE',

  /**
   * Vyhľadávanie a filtrovanie formulárových šablón
   */
  FORM_TEMPLATE_LIST = 'FORM_TEMPLATE_LIST',

  /**
   * Čítanie vyplnených formulárov
   */
  FILLED_FORM_READ = 'FILLED_FORM_READ',

  /**
   * Spravovanie vyplnených formulárov
   */
  FILLED_FORM_WRITE = 'FILLED_FORM_WRITE',

  /**
   * Vyhľadávanie a filtrovanie vyplnených formulárov
   */
  FILLED_FORM_LIST = 'FILLED_FORM_LIST',

  /**
   * Čítanie formulárových rozložení
   */
  FORM_LAYOUT_READ = 'FORM_LAYOUT_READ',

  /**
   * Spravovanie formulárových rozložení
   */
  FORM_LAYOUT_WRITE = 'FORM_LAYOUT_WRITE',

  /**
   * Vyhľadávanie a filtrovanie formulárových rozložení
   */
  FORM_LAYOUT_LIST = 'FORM_LAYOUT_LIST',

  /**
   * Čítanie formulárových rozložení
   */
  FORM_LIBRARY_READ = 'FORM_LIBRARY_READ',

  /**
   * Spravovanie formulárových rozložení
   */
  FORM_LIBRARY_WRITE = 'FORM_LIBRARY_WRITE',

  /**
   * Vyhľadávanie a filtrovanie formulárových rozložení
   */
  FORM_LIBRARY_LIST = 'FORM_LIBRARY_LIST',

  /**
   * Čítanie SAP atributov vyplnených formulárov
   */
  FILLED_FORM_SAP_ATTRIBUTE_READ = 'FILLED_FORM_SAP_ATTRIBUTE_READ',

  /**
   * Spravovanie SAP atributov vyplnených formulárov
   */
  FILLED_FORM_SAP_ATTRIBUTE_WRITE = 'FILLED_FORM_SAP_ATTRIBUTE_WRITE',

  /**
   * Vyhľadávanie a filtrovanie SAP atributov vyplnených formulárov
   */
  FILLED_FORM_SAP_ATTRIBUTE_LIST = 'FILLED_FORM_SAP_ATTRIBUTE_LIST',

  /**
   * Vyhľadávanie a filtrovanie SPHL
   */
  SPHL_LIST = 'SPHL_LIST',

  /**
   * Čítanie SPHL
   */

  SPHL_READ = 'SPHL_READ',
  /**
   * Spravovanie SPHL
   */
  SPHL_WRITE = 'SPHL_WRITE',

  /**
   * Čítanie typov technických objektov
   */
  TECHNICAL_OBJECT_TYPE_READ = 'TECHNICAL_OBJECT_TYPE_READ',

  /**
   * Vyhľadávanie a filtrovanie typov technických objektov
   */
  TECHNICAL_OBJECT_TYPE_LIST = 'TECHNICAL_OBJECT_TYPE_LIST',

  /**
   * Čítanie technických objektov
   * */
  TECHNICAL_OBJECT_READ = 'TECHNICAL_OBJECT_READ',

  /**
   * Vyhľadávanie a filtrovanie technických objektov
   */
  TECHNICAL_OBJECT_LIST = 'TECHNICAL_OBJECT_LIST',

  /**
   * Vyhľadávanie a filtrovanie metod odpojenia
   */
  METHOD_OD_LIST = 'METHOD_OD_LIST',

  /**
   * Čítanie metody odpojenia
   */

  METHOD_OD_READ = 'METHOD_OD_READ',

  /**
   * Spravovanie metody odpojenia
   */
  METHOD_OD_WRITE = 'METHOD_OD_WRITE',

  /**
   * Vyhľadávanie a filtrovanie druhov zariadení
   */
  DEVICE_TYPE_LIST = 'DEVICE_TYPE_LIST',

  /**
   * Čítanie druhu zariadenia
   */

  DEVICE_TYPE_READ = 'DEVICE_TYPE_READ',

  /**
   * Spravovanie druov zariadení
   */
  DEVICE_TYPE_WRITE = 'DEVICE_TYPE_WRITE',

  /**
   * Čítanie napäťových úrovní
   */
  VOLTAGE_LEVEL_READ = 'VOLTAGE_LEVEL_READ',

  /**
   * Vyhľadávanie a filtrovanie napäťových úrovní
   */
  VOLTAGE_LEVEL_LIST = 'VOLTAGE_LEVEL_LIST',

  /**
   * Čítanie typov nedostatkov
   */
  DEFECT_TYPE_READ = 'DEFECT_TYPE_READ',

  /**
   * Vyhľadávanie a filtrovanie typov nedostatkov
   */
  DEFECT_TYPE_LIST = 'DEFECT_TYPE_LIST',

  /**
   * Čítanie typov aktivít
   */
  ACTIVITY_TYPE_READ = 'ACTIVITY_TYPE_READ',

  /**
   * Vyhľadávanie a filtrovanie typov aktivít
   */
  ACTIVITY_TYPE_LIST = 'ACTIVITY_TYPE_LIST',

  /**
   * Čítanie typov kontrol
   */
  CONTROL_TYPE_READ = 'CONTROL_TYPE_READ',

  /**
   * Vyhľadávanie a filtrovanie typov kontrol
   */
  CONTROL_TYPE_LIST = 'CONTROL_TYPE_LIST',

  /**
   * Čítanie spôsobov odpojenia
   */
  DISCONNECTION_STATUS_READ = 'DISCONNECTION_STATUS_READ',

  /**
   * Vyhľadávanie a filtrovanie spôsobov odpojenia
   */
  DISCONNECTION_STATUS_LIST = 'DISCONNECTION_STATUS_LIST',

  /**
   * Spravovanie spôsobov odpojenia
   */
  DISCONNECTION_STATUS_WRITE = 'DISCONNECTION_STATUS_WRITE',

  /**
   * Čítanie odpojení kondenzátorov
   */
  CAPACITOR_DISCONNECTION_READ = 'CAPACITOR_DISCONNECTION_READ',

  /**
   * Vyhľadávanie a filtrovanie odpojení kondenzátorov
   */
  CAPACITOR_DISCONNECTION_LIST = 'CAPACITOR_DISCONNECTION_LIST',

  /**
   * Spravovanie spôsobov odpojení kondenzátorov
   */
  CAPACITOR_DISCONNECTION_WRITE = 'CAPACITOR_DISCONNECTION_WRITE',

  /**
   * Čítanie nedostatkov
   */
  DEFECT_READ = 'DEFECT_READ',

  /**
   * Spravovanie nedostatkov
   */
  DEFECT_WRITE = 'DEFECT_WRITE',

  /**
   * Vyhľadávanie a filtrovanie nedostatkov
   */
  DEFECT_LIST = 'DEFECT_LIST',

  /**
   * Čítanie činností
   */
  ACTIVITY_READ = 'ACTIVITY_READ',

  /**
   * Spravovanie činností
   */
  ACTIVITY_WRITE = 'ACTIVITY_WRITE',

  /**
   * Vyhľadávanie a filtrovanie činností
   */
  ACTIVITY_LIST = 'ACTIVITY_LIST',

  /**
   * Čítanie úkonov
   */
  OPERATION_READ = 'OPERATION_READ',

  /**
   * Vyhľadávanie a filtrovanie úkonov
   */
  OPERATION_LIST = 'OPERATION_LIST',

  /**
   * Spravovanie úkonov
   */
  OPERATION_WRITE = 'OPERATION_WRITE',

  /**
   * Čítanie typov úkonov
   */
  OPERATION_TYPE_READ = 'OPERATION_TYPE_READ',

  /**
   * Vyhľadávanie a filtrovanie typov úkonov
   */
  OPERATION_TYPE_LIST = 'OPERATION_TYPE_LIST',

  /**
   * Spravovanie typov úkonov
   */
  OPERATION_TYPE_WRITE = 'OPERATION_TYPE_WRITE',

  /**
   * Spravovanie mismatch
   */
  MISMATCH_WRITE = 'MISMATCH_WRITE',
  /**
   * Citanie mismatch
   */
  MISMATCH_READ = 'MISMATCH_READ',
  /**
   * Listovanie mismatch
   */
  MISMATCH_LIST = 'MISMATCH_LIST',
  /**
   * Čítanie globálnych nastavení
   * */
  GLOBAL_SETTINGS_READ = 'GLOBAL_SETTINGS_READ',
  /**
   * Spravovanie globálnych nastavení
   * */
  GLOBAL_SETTINGS_WRITE = 'GLOBAL_SETTINGS_WRITE',
}
