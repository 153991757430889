import React, { useState } from 'react';
import { TechnicalObjectOracle } from 'src/models/technical-object';
import BrightnessIcon from '@material-ui/icons/Brightness6';
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';
import LabelIcon from '@material-ui/icons/Label';
import LabelOffIcon from '@material-ui/icons/LabelOff';
import LayersIcon from '@material-ui/icons/Layers';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { useFormSelector } from '@eas/common-web';
import { MapContainer } from '@composite/map';
import {
  MapTechnicalObjectsContext,
  useTechnicalObjectsOnMap,
} from '@composite/map/context/map-technical-objects-context';
import MapControlButton from '@composite/map/controls/map-control-button';
import { useMapControl } from '@composite/map/controls/map-control-hook';
import { useMapTileLayerControl } from '@composite/map/controls/map-control-tile-layers';
import { MapControlWrapper } from '@composite/map/controls/map-control-wrapper';
import TechnicalObjectMapMarker from '@composite/map/markers/technical-objects/technical-object-marker';
import { TechnicalObject } from '@models';
import { getTosWithingRange } from './technical-object-within-range';

function TechnicalObjectMapView() {
  const { fid, type, coordinates, underground } = useFormSelector(
    (data: TechnicalObject) => ({
      fid: data.fid,
      type: data.type,
      coordinates: data.coordinates,
      underground: data.underground,
    })
  );

  const [selected, setSelected] = useState(false);

  const [tosWithinRange, setTosWithinRange] = useState<TechnicalObjectOracle[]>(
    []
  );
  const [mapKey, setMapKey] = useState(0);
  const { mapRef, zoomIn, zoomOut, resetView } = useMapControl();
  const { TileLayer, nextTileLayer, tileLayerRef, switchContrast } =
    useMapTileLayerControl();

  const { setShowLabels, showLabels } = useTechnicalObjectsOnMap({
    initialShowLabels: true,
  });

  if (!coordinates || (coordinates?.length ?? 0) === 0) {
    return <></>;
  }

  return (
    <MapTechnicalObjectsContext.Provider value={{ showLabels, setShowLabels }}>
      <div style={{ width: '100%', height: 600 }}>
        <MapContainer
          zoomControl={false}
          key={`${fid}-${mapKey}`}
          ref={mapRef}
          doubleClickZoom={false}
          initialZoom={20}
          initialCenter={{
            latitude: coordinates?.[0].latitude,
            longitude: coordinates?.[0].longitude,
          }}
        >
          <MapControlWrapper position="topright">
            <MapControlButton
              label={<LayersIcon />}
              onClick={nextTileLayer}
              tooltip="Prepnúť vrstvu"
            />
            <MapControlButton
              label={<BrightnessIcon />}
              onClick={switchContrast}
              tooltip="Zmeniť viditeľnosť vrstvy"
            />
            <MapControlButton
              label={<ZoomInIcon />}
              onClick={zoomIn}
              tooltip="Priblížť"
            />
            <MapControlButton
              label={<ZoomOutIcon />}
              onClick={zoomOut}
              tooltip="Oddialiť"
            />
            <MapControlButton
              tooltip="Vycentrovať"
              label={<CenterFocusStrongIcon />}
              onClick={() =>
                resetView(
                  {
                    latitude: coordinates?.[0].latitude,
                    longitude: coordinates?.[0].longitude,
                  },
                  20
                )
              }
            />

            <MapTechnicalObjectsContext.Consumer>
              {({ showLabels, setShowLabels }) => (
                <MapControlButton
                  tooltip="Zobraziť popisky"
                  label={showLabels ? <LabelIcon /> : <LabelOffIcon />}
                  onClick={() => setShowLabels?.((p) => !p)}
                />
              )}
            </MapTechnicalObjectsContext.Consumer>

            <MapControlButton
              label={<TrackChangesIcon />}
              onClick={async () => {
                try {
                  const tos = await getTosWithingRange(coordinates[0]);
                  if (!tos || tos?.length === 0) {
                    return;
                  }
                  if (!tos[0].coordinates?.[0]) {
                    return;
                  }
                  setTosWithinRange(tos);
                  setMapKey((p) => p + 1);

                  resetView(
                    {
                      latitude: tos[0].coordinates?.[0].latitude,
                      longitude: tos[0].coordinates?.[0].longitude,
                    },
                    20
                  );
                } catch (error) {
                  console.log(error);
                }
              }}
              tooltip="TO v okruhu 500m"
            />
          </MapControlWrapper>

          {tosWithinRange.map((t) => (
            <TechnicalObjectMapMarker
              key={t.fid}
              techObject={t as unknown as TechnicalObject}
            />
          ))}
          <TechnicalObjectMapMarker
            isSelected={selected}
            techObject={{
              fid,
              type,
              coordinates,
              underground,
            }}
            onClick={() => {
              setSelected((p) => !p);
            }}
          />
          <TileLayer ref={tileLayerRef} />
        </MapContainer>
      </div>
    </MapTechnicalObjectsContext.Provider>
  );
}
export default TechnicalObjectMapView;
