import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import BrightnessIcon from '@material-ui/icons/Brightness6';
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';
import LabelIcon from '@material-ui/icons/Label';
import LabelOffIcon from '@material-ui/icons/LabelOff';
import LayersIcon from '@material-ui/icons/Layers';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import {
  FormPanel,
  FormTextField,
  TableField,
  useFormSelector,
} from '@eas/common-web';
import { useTechnicalObjectsList } from '@modules/technical-object/technical-object-api';
import {
  BRATISLAVA_COORDS_GPS_POINT,
  MapContainer,
  WorkOrderMarker,
} from '@composite/map';
import {
  MapTechnicalObjectsContext,
  useTechnicalObjectsOnMap,
} from '@composite/map/context/map-technical-objects-context';
import MapControlButton from '@composite/map/controls/map-control-button';
import { useMapControl } from '@composite/map/controls/map-control-hook';
import { useMapTileLayerControl } from '@composite/map/controls/map-control-tile-layers';
import { MapControlWrapper } from '@composite/map/controls/map-control-wrapper';
import TechnicalObjectMapMarker from '@composite/map/markers/technical-objects/technical-object-marker';
import { TechnicalObject, WorkOrder } from '@models';

export function MuzaFields() {
  const { coordinates, woId, woIdentifier, noCoordinates } = useFormSelector(
    (data: WorkOrder) => ({
      woId: data.id,
      woIdentifier: data.workOrderId,
      coordinates: {
        latitude: data?.latitude ?? BRATISLAVA_COORDS_GPS_POINT.latitude,
        longitude: data?.longitude ?? BRATISLAVA_COORDS_GPS_POINT.longitude,
      },
      noCoordinates: !data?.latitude || !data?.latitude,
    })
  );

  const [selected, setSelected] = useState<string>();

  const technicalObjects = useTechnicalObjectsList(woId)[0] ?? [];

  const { mapRef, zoomIn, zoomOut, resetView } = useMapControl();
  const { TileLayer, nextTileLayer, tileLayerRef, switchContrast } =
    useMapTileLayerControl();

  const { setShowLabels, showLabels } = useTechnicalObjectsOnMap({
    initialShowLabels: true,
  });

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__WORK_ORDERS__MUZA__PANEL__GENERAL"
            defaultMessage="Všeobecné"
          />
        }
      >
        <FormTextField
          name="muzaDescription"
          label={
            <FormattedMessage
              id="ZSD__TECHNICAL_OBJECT__DETAIL__FIELD_LABEL__MUZA_DESCRIPTION"
              defaultMessage="Popis"
            />
          }
          disabled
        />
        <FormTextField
          name="revisionUnit"
          label={
            <FormattedMessage
              id="ZSD__TECHNICAL_OBJECT__DETAIL__FIELD_LABEL__MUZA_REVISION_UNIT"
              defaultMessage="Revízny celok"
            />
          }
          disabled
        />
        <FormTextField
          name="ipid"
          label={
            <FormattedMessage
              id="ZSD__TECHNICAL_OBJECT__DETAIL__FIELD_LABEL__MUZA_IPID"
              defaultMessage="IPID"
            />
          }
          disabled
        />
        <FormTextField
          name="numberOfTechnicalObjects"
          label={
            <FormattedMessage
              id="ZSD__TECHNICAL_OBJECT__DETAIL__FIELD_LABEL__MUZA_NUM_OF_TO"
              defaultMessage="Počet technických objektov"
            />
          }
          disabled
        />

        <FormTextField
          name="numberOfPb"
          label={
            <FormattedMessage
              id="ZSD__TECHNICAL_OBJECT__DETAIL__FIELD_LABEL__MUZA_NUM_OF_PB"
              defaultMessage="Počet PB"
            />
          }
          disabled
        />
        <FormTextField
          name="workSection"
          label={
            <FormattedMessage
              id="ZSD__TECHNICAL_OBJECT__DETAIL__FIELD_LABEL__MUZA_WORK_SECTION"
              defaultMessage="Úsek práce"
            />
          }
          disabled
        />
        <FormTextField
          name="workDirection"
          label={
            <FormattedMessage
              id="ZSD__TECHNICAL_OBJECT__DETAIL__FIELD_LABEL__MUZA_WORK_DIRECTION"
              defaultMessage="Odkiaľ - kam"
            />
          }
          disabled
        />
        <FormTextField
          name="kmLength"
          label={
            <FormattedMessage
              id="ZSD__TECHNICAL_OBJECT__DETAIL__FIELD_LABEL__MUZA_KM_LENGTH"
              defaultMessage="Dĺžka v km"
            />
          }
          disabled
        />
        <FormTextField
          name="airPercentage"
          label={
            <FormattedMessage
              id="ZSD__TECHNICAL_OBJECT__DETAIL__FIELD_LABEL__MUZA_AIR_PERCENTS"
              defaultMessage="Percento vzduch"
            />
          }
          disabled
        />
        <FormTextField
          name="cablePercentage"
          label={
            <FormattedMessage
              id="ZSD__TECHNICAL_OBJECT__DETAIL__FIELD_LABEL__MUZA_CABLE_PERCENTS"
              defaultMessage="Percento kábel"
            />
          }
          disabled
        />
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__WORK_ORDERS__MUZA__PANEL__TECH_OBJECTS_LIST"
            defaultMessage="Zoznam technických objektov"
          />
        }
      >
        <TableField
          value={technicalObjects}
          onChange={() => {}}
          disabled={false}
          showDetailBtnCond={() => false}
          showRadioCond={() => false}
          createRowStyle={(to) => {
            if ((to as TechnicalObject)?.fid === selected) {
              return {
                backgroundColor: '#e4626c17',
                fontWeight: '800!important',
                cursor: 'pointer',
              };
            }
            return { cursor: 'pointer' };
          }}
          showToolbar={false}
          columns={[
            { datakey: 'fid', name: 'FID', width: 200 },
            { datakey: 'type.name', name: 'Typ', width: 200 },
            {
              datakey: 'id',
              name: 'Detail',
              width: 200,
              CellComponent: (row) => (
                <div>
                  <Button
                    variant="text"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      window.open(
                        `/zsd/technicke-objekty/${row?.value}`,
                        '_blank'
                      );
                    }}
                  >
                    <OpenInNewIcon fontSize="small" />
                  </Button>
                </div>
              ),
            },
          ]}
        />
        {!noCoordinates && (
          <FormPanel
            defaultExpanded={false}
            expandable={true}
            label={
              <FormattedMessage
                id="ZSD__WORK_ORDERS__MUZA__PANEL__TECH_OBJECTS_MAP"
                defaultMessage="Zobrazenie na mape"
              />
            }
          >
            <MapTechnicalObjectsContext.Provider
              value={{ showLabels, setShowLabels }}
            >
              <div style={{ width: '100%', height: 600 }}>
                <MapContainer
                  key={`map-${woId}`}
                  zoomControl={false}
                  ref={mapRef}
                  doubleClickZoom={false}
                  initialZoom={19}
                  initialCenter={{
                    ...coordinates,
                  }}
                >
                  <MapControlWrapper position="topright">
                    <MapControlButton
                      label={<LayersIcon />}
                      onClick={nextTileLayer}
                      tooltip="Prepnúť vrstvu"
                    />
                    <MapControlButton
                      label={<BrightnessIcon />}
                      onClick={switchContrast}
                      tooltip="Zmeniť viditeľnosť vrstvy"
                    />
                    <MapControlButton
                      label={<ZoomInIcon />}
                      onClick={zoomIn}
                      tooltip="Priblížť"
                    />
                    <MapControlButton
                      label={<ZoomOutIcon />}
                      onClick={zoomOut}
                      tooltip="Oddialiť"
                    />
                    <MapControlButton
                      label={<CenterFocusStrongIcon />}
                      onClick={() => resetView(coordinates, 20)}
                      tooltip="Vycentrovať"
                    />

                    <MapTechnicalObjectsContext.Consumer>
                      {({ showLabels, setShowLabels }) => (
                        <MapControlButton
                          label={showLabels ? <LabelIcon /> : <LabelOffIcon />}
                          onClick={() => setShowLabels?.((p) => !p)}
                          tooltip="Zobraziť popisky"
                        />
                      )}
                    </MapTechnicalObjectsContext.Consumer>
                  </MapControlWrapper>
                  {coordinates && (
                    <WorkOrderMarker
                      tooltip={{
                        content: `Pracovný príkaz ${woIdentifier}`,
                        permanent: true,
                      }}
                      label=""
                      point={[coordinates.latitude!, coordinates.longitude!]}
                    />
                  )}
                  {(technicalObjects ?? []).map((to) => (
                    <TechnicalObjectMapMarker
                      key={to.fid}
                      isSelected={selected === to.fid}
                      techObject={to}
                      onClick={() => {
                        setSelected(to.fid);
                      }}
                    />
                  ))}

                  <TileLayer ref={tileLayerRef} />
                </MapContainer>
              </div>
            </MapTechnicalObjectsContext.Provider>
          </FormPanel>
        )}
      </FormPanel>
    </>
  );
}
