import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DictionaryEvidence,
  FormNumberField,
  FormPanel,
  FormTableField,
  FormTextField,
} from '@eas/common-web';

export function Fields() {
  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__DEFECT_TYPE__DETAIL__PANEL__GENERAL"
            defaultMessage="Všeobecné"
          />
        }
      >
        <DictionaryEvidence.FieldName />
        <FormTextField
          name="code"
          label={
            <FormattedMessage
              id="ZSD__DEFECT_TYPE__DETAIL__FIELD__CODE"
              defaultMessage="Kód"
            />
          }
        />
        <FormTextField
          name="technicalObjectType.name"
          label={
            <FormattedMessage
              id="ZSD__DEFECT_TYPE__DETAIL__FIELD__TECHNICAL_OBJECT_TYPE"
              defaultMessage="Typ technického objektu"
            />
          }
        />
        <FormNumberField
          name="severity"
          label={
            <FormattedMessage
              id="ZSD__DEFECT_TYPE__DETAIL__FIELD__SEVERITY"
              defaultMessage="Závažnosť"
            />
          }
        />
        <FormTextField
          name="category.name"
          label={
            <FormattedMessage
              id="ZSD__DEFECT_TYPE__DETAIL__FIELD__CATEGORY"
              defaultMessage="Typ kontrolného bodu"
            />
          }
        />
        <FormTextField
          name="controlType.name"
          label={
            <FormattedMessage
              id="ZSD__DEFECT_TYPE__DETAIL__FIELD__CONTROL_TYPE"
              defaultMessage="Typ kontroly"
            />
          }
        />
        <FormTextField
          name="technicalObjectModel"
          label={
            <FormattedMessage
              id="ZSD__DEFECT_TYPE__DETAIL__FIELD__TECHNICAL_OBJECT_MODEL"
              defaultMessage="Model technického objektu"
            />
          }
        />
        <FormTextField
          name="kst"
          label={
            <FormattedMessage
              id="ZSD__DEFECT_TYPE__DETAIL__FIELD__KST"
              defaultMessage="KST"
            />
          }
        />
        <FormTextField
          name="operationName"
          label={
            <FormattedMessage
              id="ZSD__DEFECT_TYPE__DETAIL__FIELD__OPERATION_NAME"
              defaultMessage="Názov operácie"
            />
          }
        />
        <FormTextField
          name="functionalUnit"
          label={
            <FormattedMessage
              id="ZSD__DEFECT_TYPE__DETAIL__FIELD__FUNCTIONAL_UNIT"
              defaultMessage="Funkčná jednotka"
            />
          }
        />
        <FormNumberField
          name="order"
          label={
            <FormattedMessage
              id="ZSD__DEFECT_TYPE__DETAIL__FIELD__ORDER"
              defaultMessage="Poradie"
            />
          }
        />
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__DEFECT_TYPE__DETAIL__FIELD__SOLUTIONS_PANEL"
            defaultMessage="Riešenia"
          />
        }
        expandable={false}
        defaultExpanded={true}
      >
        <FormTableField
          name="solutions"
          disabled={true}
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
          showDetailBtnCond={() => false}
          showToolbar={false}
          columns={[
            {
              datakey: 'name',
              name: 'Názov',
              width: 600,
              CellComponent: (row) => <div>{row.rowValue}</div>,
            },
          ]}
        />
      </FormPanel>
    </>
  );
}
