import { useDndMonitor } from '@dnd-kit/core';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTheme } from '@material-ui/core/styles';
import { AbsenceBar } from '@modules/planner/calendar/chart/bars/bars-row/absence/absence-bar';
import { getScaleItemWidth } from '@modules/planner/calendar/utils/get-scale-item-width';
import { getSecondsInPixel } from '@modules/planner/calendar/utils/get-seconds-in-pixel';
import { Dropppable } from '@modules/planner/dnd/droppable';
import { prepareDto } from '@modules/planner/dnd/utils/prepare-dto';
import {
  getCollisions,
  getNextWorkOrder,
  getPositions,
  getPreviousWorkOrder,
} from '@composite/calendar/utils';
import { substractTime } from '@composite/calendar/utils/get-template';
import { TableRowHandlersContext } from '@composite/table/table-row-handlers-context';
import { CachedWorkOrder } from '@composite/websocket/listen-to-work-order-changes';
import { ShiftInterval } from '@models';
import { CalendarDimensions } from '@enums';
import { CalendarContext } from '../../../calendar-context';
import { CalendarDataType, CalendarDndData } from '../../../calendar-types';
import { useStyles } from './bars-row-styles';
import { MultiTask } from './multitask/multitask';
import { MultiDayShift } from './shift/shift-multi-day';
import { SingleDayShift } from './shift/shift-single-day';
import { Task } from './task/task';

dayjs.extend(utc);
dayjs.extend(timezone);

interface BarsRowProps {
  barData: CalendarDataType;
  index: number;
  style: CSSProperties;
  refreshRow: (rowId: string) => void;
}

export function BarsRow({ barData, index, style, refreshRow }: BarsRowProps) {
  const { settings, currentDate, setEdgeWorkOrders } =
    useContext(CalendarContext);
  const classes = useStyles();
  const theme = useTheme();

  const [isDroppable, setIsDroppable] = useState(false);
  const [localWorkOrders, setLocalWorkOrders] = useState(
    barData.workOrders ?? []
  );

  const { useRegisterRow } = useContext(TableRowHandlersContext);

  useEffect(() => {
    setLocalWorkOrders(barData.workOrders ?? []);
    refreshRow(barData.id);
  }, [barData.workOrders, refreshRow, barData.id]);

  useRegisterRow(barData?.id, (changedWorkOrder: CachedWorkOrder) => {
    setLocalWorkOrders((p) => {
      if (changedWorkOrder.remove) {
        return p.filter((w) => w.id !== changedWorkOrder.id);
      }
      const currentIndex = p.findIndex((w) => w.id === changedWorkOrder.id);
      if (currentIndex === -1) {
        return [...p, changedWorkOrder.data];
      }
      const original = [...p];
      return [
        ...original.slice(0, currentIndex),
        { ...original[currentIndex], ...changedWorkOrder.data },
        ...original.slice(currentIndex + 1),
      ];
    });
  });

  const getBarStyle = useCallback(
    (isBlocked: boolean) => {
      const scaleItemWidth = getScaleItemWidth(settings);

      if (isBlocked) {
        return {
          width: scaleItemWidth,
          background: `repeating-linear-gradient(135deg, #FFF 0 3px, ${theme.palette.error.light}64 0px 5px )`,
          backgroundSize: 35,
        };
      }

      return {
        width: scaleItemWidth,
        backgroundColor: isDroppable
          ? settings.colorForShiftV
          : settings.colorForShiftV,
      };
    },
    [isDroppable, settings, theme.palette.error.light]
  );

  const getShiftStyle = useCallback(
    (shiftInterval: ShiftInterval, hour: number, multipleShifts?: boolean) => {
      const intervalSize =
        getScaleItemWidth(settings) /
        (settings.endingHour - settings.startingHour);
      const secondsInPixel = getSecondsInPixel(settings);

      let width = intervalSize;
      let left = 0;
      let right = 0;

      if (shiftInterval) {
        let diff = substractTime(
          shiftInterval.startingHour,
          `${hour + settings.startingHour}:00`
        );
        if (diff < 1 && diff > 0) {
          left = (diff * 60 * 60) / secondsInPixel;
        }

        diff = substractTime(
          shiftInterval.endingHour,
          `${hour + settings.startingHour}:00`
        );
        if (diff < 1 && diff > 0) {
          right = intervalSize - (diff * 60 * 60) / secondsInPixel;
        }

        width = intervalSize - left - right;
      }

      if (shiftInterval?.type === 'P') {
        return {
          width,
          left,
          right,
          background: isDroppable
            ? settings.colorForShiftP
            : settings.colorForShiftP,
        };
      }

      if (shiftInterval?.type === 'ZP') {
        return {
          width,
          left,
          right,
          background: isDroppable
            ? settings.colorForShiftZP
            : settings.colorForShiftZP,
        };
      }

      if (shiftInterval?.type === 'Z') {
        return {
          width,
          left,
          right,
          background: isDroppable
            ? settings.colorForShiftZ
            : settings.colorForShiftZ,
        };
      }

      if (shiftInterval?.type === 'A') {
        return {
          width,
          left,
          right,
          background: isDroppable
            ? settings.colorForShiftA
            : settings.colorForShiftA,
        };
      }

      if (shiftInterval?.type === 'O') {
        return {
          width,
          left,
          right,
          background: `repeating-linear-gradient(135deg, #FFF 0 3px, ${theme.palette.error.light}64 0px 5px )`,
          backgroundSize: 35,
        };
      }
      // multipleShifts: Render free time interval only if another layer has already been drawn and needs to be covered.
      if (multipleShifts && shiftInterval?.type === 'V') {
        return {
          width,
          left,
          right,
          background: settings.colorForShiftV,
        };
      }

      return {};
    },
    [isDroppable, settings, theme.palette.error.light]
  );

  useDndMonitor({
    onDragStart: () => {
      setIsDroppable(true);
    },
    onDragEnd: () => {
      setIsDroppable(false);
    },
    onDragMove: (e) => {
      if (e.over) {
        // skip if moving is above different mobile unit
        if (
          e.over.data.current?.unitId !== barData.id ||
          !e.collisions?.[0] ||
          !e.active.data.current?.length
        ) {
          return;
        }

        // selected work order to plan
        const workOrders = e.active.data.current;

        // hovered mobile unit + her already planned work orders
        const unitId = e.over.data.current?.unitId;
        const barWorkOrders = localWorkOrders?.filter(
          (wo) => (wo.user?.id ?? wo.mobileUnit?.id) === unitId
        );

        const newWorkOrder = prepareDto({
          currentDate: currentDate ?? 0,
          collision: e.collisions[0],
          workOrder: workOrders[0],
          offset: 0,
        });

        const previousWorkOrder = getPreviousWorkOrder(
          barWorkOrders ?? [],
          dayjs.tz(newWorkOrder.plannedFrom).unix()
        );

        const nextWorkOrder = getNextWorkOrder(
          barWorkOrders ?? [],
          dayjs.tz(newWorkOrder.plannedTo).unix()
        );

        setEdgeWorkOrders((orders) => {
          const equals =
            orders[0] === previousWorkOrder && orders[1] === nextWorkOrder;

          if (equals) {
            return orders;
          } else {
            return [previousWorkOrder, nextWorkOrder];
          }
        });
      } else {
        // reset edge work orders if moving is not above any mobile unit
        setEdgeWorkOrders((orders) => {
          if (orders[0] === undefined && orders[1] === undefined) {
            return orders;
          } else {
            return [undefined, undefined];
          }
        });
      }
    },
  });

  const variousData = useMemo(() => {
    const collisions = getCollisions(localWorkOrders ?? []);
    const positions = getPositions(localWorkOrders ?? [], collisions);
    const maxPosition = Math.max(...Object.values(positions));
    const startingPoint = {
      latitude: barData.latitude,
      longitude: barData.longitude,
    };

    const data: CalendarDndData = {
      unitId: barData.id,
      unitType: barData.unitType,
      settings,
      startingPoint,
    };
    return { collisions, positions, dndData: data, maxPosition };
  }, [
    barData.id,
    barData.latitude,
    barData.longitude,
    barData.unitType,
    localWorkOrders,
    settings,
  ]);

  return (
    <Dropppable
      style={{
        ...style,
        height: settings.rowHeight,
        borderRight: '1px solid rgba(0,0,0,0.12)',
      }}
      className={classes.steps}
      id={`${index}`}
      data={variousData.dndData}
    >
      {settings.dimension === CalendarDimensions.DAY && (
        <SingleDayShift
          barData={barData}
          getBarStyle={getBarStyle}
          getShiftStyle={getShiftStyle}
        />
      )}
      {settings.dimension !== CalendarDimensions.DAY && (
        <MultiDayShift
          barData={barData}
          getBarStyle={getBarStyle}
          getShiftStyle={getShiftStyle}
        />
      )}

      {barData.multitasks?.map((multitask, index) => (
        <MultiTask
          key={`multitask-${index}`}
          multitask={multitask}
          unitName={barData.name}
        />
      ))}

      {localWorkOrders?.map((workOrder) => (
        <Task
          key={workOrder.id}
          workOrder={workOrder}
          overlayCount={
            variousData.collisions[workOrder.id].length > 0
              ? Math.min(
                  variousData.collisions[workOrder.id].length + 1,
                  variousData.maxPosition
                )
              : 1
          }
          position={variousData.positions[workOrder.id] ?? 1}
        />
      ))}
      <AbsenceBar barData={barData} />
    </Dropppable>
  );
}
