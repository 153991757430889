import { createContext } from 'react';
import { CalendarContextType } from './calendar-types';

export const CalendarContext = createContext<CalendarContextType>({
  dataSource: {
    yAxis: {
      data: [],
      refreshData: () => {},
      recreateData: () => {},
    },
    holidays: [],
  },
  wrapRef: { current: null },
  settings: {
    rowHeight: 40,
    headerHeight: 75,
    legendWidth: 200,
    stepWidth: 100,
    startingHour: 0,
    endingHour: 24,
    colorForShiftV: '#D9D9D940',
    colorForShiftZ: '#ffffff',
    colorForShiftZP: '#e2edfd',
    colorForShiftA: '#bfe3c3',
    colorForShiftP: '#f9dddf',
    groupToMultitaskCount: 15,
  },
  setSettings: () => {},
  currentDate: 0,
  setCurrentDate: () => 0,
  classes: {} as any,
});
