import React from 'react';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AlarmOffIcon from '@material-ui/icons/AlarmOff';
import AmpStoriesIcon from '@material-ui/icons/AmpStories';
import AppsIcon from '@material-ui/icons/Apps';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BrushIcon from '@material-ui/icons/Brush';
import BuildIcon from '@material-ui/icons/Build';
import CategoryIcon from '@material-ui/icons/Category';
import CreateIcon from '@material-ui/icons/Create';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DoneIcon from '@material-ui/icons/Done';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import EventIcon from '@material-ui/icons/Event';
import FormatShapesIcon from '@material-ui/icons/FormatShapes';
import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast';
import HomeWorkOutlined from '@material-ui/icons/HomeWorkOutlined';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LoopIcon from '@material-ui/icons/Loop';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import PowerIcon from '@material-ui/icons/PowerOutlined';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import { MenuItem, NavigationContext, UserContext } from '@eas/common-web';
import { EvidenceBrowserUrl, Permission } from '@enums';

type ZsdMenuItem = MenuItem & {
  items?: ZsdMenuItem[];
  permission?: string[];
};

export function useMenuItems() {
  const { navigate } = useContext(NavigationContext);
  const { hasPermission } = useContext(UserContext);

  const items: ZsdMenuItem[] = [
    {
      label: (
        <FormattedMessage id="ZSD__MENU__CALENDAR" defaultMessage="Kalendár" />
      ),
      isActive: (pathname) => {
        return [EvidenceBrowserUrl.CALENDARS].includes(
          pathname as EvidenceBrowserUrl
        );
      },
      icon: <DateRangeIcon />,
      onClick: () => navigate(EvidenceBrowserUrl.CALENDARS),
      href: EvidenceBrowserUrl.CALENDARS,
      permission: [Permission.CALENDAR_LIST],
    },
    {
      label: (
        <FormattedMessage
          id="ZSD__MENU__USER_GROUP"
          defaultMessage="Používatelia"
        />
      ),
      isActive: (pathname) => {
        return [EvidenceBrowserUrl.ROLES, EvidenceBrowserUrl.USERS].includes(
          pathname as EvidenceBrowserUrl
        );
      },
      icon: <PermIdentityIcon />,
      items: [
        {
          label: (
            <FormattedMessage
              id="ZSD__MENU__SHIFTS"
              defaultMessage="Správa zmien"
            />
          ),
          icon: <AccessTimeIcon />,
          onClick: () => navigate(EvidenceBrowserUrl.SHIFTS),
          href: EvidenceBrowserUrl.SHIFTS,
          isActive: (pathname) => {
            return [EvidenceBrowserUrl.SHIFTS].includes(
              pathname as EvidenceBrowserUrl
            );
          },
          permission: [Permission.SHIFT_LIST],
        },
        {
          label: (
            <FormattedMessage
              id="ZSD__MENU__CALENDAR_TEMPLATES"
              defaultMessage="Správa kalendárových šablón"
            />
          ),
          icon: <EventIcon />,
          onClick: () => navigate(EvidenceBrowserUrl.CALENDAR_TEMPLATES),
          href: EvidenceBrowserUrl.CALENDAR_TEMPLATES,
          isActive: (pathname) => {
            return [EvidenceBrowserUrl.CALENDAR_TEMPLATES].includes(
              pathname as EvidenceBrowserUrl
            );
          },
          permission: [Permission.CALENDAR_TEMPLATE_LIST],
        },
        {
          separator: true,
        },
        {
          label: (
            <FormattedMessage
              id="ZSD__MENU__USERS"
              defaultMessage="Správa používateľov"
            />
          ),
          icon: <PeopleOutlineIcon />,
          onClick: () => navigate(EvidenceBrowserUrl.USERS),
          href: EvidenceBrowserUrl.USERS,
          isActive: (pathname) => {
            return [EvidenceBrowserUrl.USERS].includes(
              pathname as EvidenceBrowserUrl
            );
          },
          permission: [Permission.USER_LIST],
        },
        {
          label: (
            <FormattedMessage
              id="ZSD__MENU__ROLES"
              defaultMessage="Správa oprávnení"
            />
          ),
          icon: <DoneIcon />,
          onClick: () => navigate(EvidenceBrowserUrl.ROLES),
          href: EvidenceBrowserUrl.ROLES,
          isActive: (pathname) => {
            return [EvidenceBrowserUrl.ROLES].includes(
              pathname as EvidenceBrowserUrl
            );
          },
          permission: [Permission.ROLE_LIST],
        },
      ],
    },
    {
      label: (
        <FormattedMessage
          id="ZSD__MENU__REGIONS"
          defaultMessage="Org. štruktúra"
        />
      ),
      isActive: (pathname) => {
        return [
          EvidenceBrowserUrl.SECTIONS,
          EvidenceBrowserUrl.DEPARTMENTS,
          EvidenceBrowserUrl.TEAMS,
        ].includes(pathname as EvidenceBrowserUrl);
      },
      icon: <LocationOnIcon />,
      items: [
        {
          label: (
            <FormattedMessage id="ZSD__MENU__SECTIONS" defaultMessage="Úseky" />
          ),
          icon: <MapOutlinedIcon />,
          onClick: () => navigate(EvidenceBrowserUrl.SECTIONS),
          href: EvidenceBrowserUrl.SECTIONS,
          isActive: (pathname) => {
            return [EvidenceBrowserUrl.SECTIONS].includes(
              pathname as EvidenceBrowserUrl
            );
          },
          permission: [Permission.SECTION_LIST],
        },
        {
          label: (
            <FormattedMessage id="ZSD__MENU__TEAMS" defaultMessage="Tímy" />
          ),
          icon: <PeopleOutlineIcon />,
          onClick: () => navigate(EvidenceBrowserUrl.TEAMS),
          href: EvidenceBrowserUrl.TEAMS,
          isActive: (pathname) => {
            return [EvidenceBrowserUrl.TEAMS].includes(
              pathname as EvidenceBrowserUrl
            );
          },
          permission: [Permission.TEAM_LIST],
        },
        {
          label: (
            <FormattedMessage
              id="ZSD__MENU__DEPARTMENTS"
              defaultMessage="Pracoviská"
            />
          ),
          icon: <HomeWorkOutlined />,
          onClick: () => navigate(EvidenceBrowserUrl.DEPARTMENTS),
          href: EvidenceBrowserUrl.DEPARTMENTS,
          isActive: (pathname) => {
            return [EvidenceBrowserUrl.DEPARTMENTS].includes(
              pathname as EvidenceBrowserUrl
            );
          },
          permission: [Permission.DEPARTMENT_LIST],
        },
      ],
    },
    {
      label: (
        <FormattedMessage
          id="ZSD__MENU__MOBILE_UNITS"
          defaultMessage="Mobilné jednotky"
        />
      ),
      isActive: (pathname) => {
        return [
          EvidenceBrowserUrl.MOBILE_UNITS,
          EvidenceBrowserUrl.MOBILE_UNITS_SWITCH,
        ].includes(pathname as EvidenceBrowserUrl);
      },
      icon: <DateRangeIcon />,
      items: [
        {
          label: (
            <FormattedMessage
              id="ZSD__MENU__MOBILE_UNITS"
              defaultMessage="Mobilné jednotky"
            />
          ),
          icon: <DateRangeIcon />,
          onClick: () => navigate(EvidenceBrowserUrl.MOBILE_UNITS),
          href: EvidenceBrowserUrl.MOBILE_UNITS,
          isActive: (pathname) => {
            return [EvidenceBrowserUrl.MOBILE_UNITS].includes(
              pathname as EvidenceBrowserUrl
            );
          },
          permission: [Permission.MOBILE_UNIT_LIST],
        },
        {
          label: (
            <FormattedMessage
              id="ZSD__MENU__MOBILE_UNITS_SWITCHER"
              defaultMessage="Rýchle zmeny"
            />
          ),
          icon: <LoopIcon />,
          onClick: () => navigate(EvidenceBrowserUrl.MOBILE_UNITS_SWITCH),
          href: EvidenceBrowserUrl.MOBILE_UNITS_SWITCH,
          isActive: (pathname) => {
            return [EvidenceBrowserUrl.MOBILE_UNITS_SWITCH].includes(
              pathname as EvidenceBrowserUrl
            );
          },
          permission: [Permission.MOBILE_UNIT_WRITE],
        },
      ],
    },
    {
      label: (
        <FormattedMessage
          id="ZSD__MENU__WORK_ORDERS"
          defaultMessage="Pracovné príkazy"
        />
      ),
      isActive: (pathname) => {
        return [EvidenceBrowserUrl.WORK_ORDERS].includes(
          pathname as EvidenceBrowserUrl
        );
      },
      icon: <WorkOutlineIcon />,
      onClick: () => navigate(EvidenceBrowserUrl.WORK_ORDERS),
      href: EvidenceBrowserUrl.WORK_ORDERS,
      permission: [Permission.WORK_ORDER_LIST],
    },
    {
      label: (
        <FormattedMessage
          id="ZSD__MENU__DICTIONARIES"
          defaultMessage="Číselníky"
        />
      ),
      isActive: (pathname) => {
        return [
          EvidenceBrowserUrl.CERTIFICATIONS,
          EvidenceBrowserUrl.HOLIDAYS,
          EvidenceBrowserUrl.WORK_ORDER_TYPES,
          EvidenceBrowserUrl.WORK_ORDER_STATES,
          EvidenceBrowserUrl.WORK_ORDER_ATTRIBUTES,
          EvidenceBrowserUrl.FILLED_FORM_ATTRIBUTES,
          EvidenceBrowserUrl.ABSENCE_TYPES,
          EvidenceBrowserUrl.SPHL,
          EvidenceBrowserUrl.METHOD_OD,
          EvidenceBrowserUrl.DEVICE_TYPE,
        ].includes(pathname as EvidenceBrowserUrl);
      },
      icon: <AmpStoriesIcon />,
      items: [
        {
          label: (
            <FormattedMessage
              id="ZSD__MENU__CERTIFICATIONS"
              defaultMessage="Zručnosti technikov"
            />
          ),
          icon: <VerifiedUserIcon />,
          onClick: () => navigate(EvidenceBrowserUrl.CERTIFICATIONS),
          href: EvidenceBrowserUrl.CERTIFICATIONS,
          isActive: (pathname) => {
            return [EvidenceBrowserUrl.CERTIFICATIONS].includes(
              pathname as EvidenceBrowserUrl
            );
          },
          permission: [Permission.CERTIFICATION_LIST],
        },
        {
          label: (
            <FormattedMessage
              id="ZSD__MENU__HOLIDAYS"
              defaultMessage="Sviatky"
            />
          ),
          icon: <FreeBreakfastIcon />,
          onClick: () => navigate(EvidenceBrowserUrl.HOLIDAYS),
          href: EvidenceBrowserUrl.HOLIDAYS,
          isActive: (pathname) => {
            return [EvidenceBrowserUrl.HOLIDAYS].includes(
              pathname as EvidenceBrowserUrl
            );
          },
          permission: [Permission.HOLIDAY_LIST],
        },
        {
          label: (
            <FormattedMessage
              id="ZSD__MENU__WORK_ORDER_TYPES"
              defaultMessage="Druhy PP"
            />
          ),
          icon: <BookmarkIcon />,
          onClick: () => navigate(EvidenceBrowserUrl.WORK_ORDER_TYPES),
          href: EvidenceBrowserUrl.WORK_ORDER_TYPES,
          isActive: (pathname) => {
            return [EvidenceBrowserUrl.WORK_ORDER_TYPES].includes(
              pathname as EvidenceBrowserUrl
            );
          },
          permission: [Permission.WORK_ORDER_TYPE_LIST],
        },
        {
          label: (
            <FormattedMessage
              id="ZSD__MENU__WORK_ORDER_STATES"
              defaultMessage="Stavy PP"
            />
          ),
          icon: <BrushIcon />,
          onClick: () => navigate(EvidenceBrowserUrl.WORK_ORDER_STATES),
          href: EvidenceBrowserUrl.WORK_ORDER_STATES,
          isActive: (pathname) => {
            return [EvidenceBrowserUrl.WORK_ORDER_STATES].includes(
              pathname as EvidenceBrowserUrl
            );
          },
          permission: [Permission.WORK_ORDER_STATE_LIST],
        },
        {
          label: (
            <FormattedMessage
              id="ZSD__MENU__WORK_ORDER_ATTRIBUTES"
              defaultMessage="SAP atribúty PP"
            />
          ),
          icon: <EditAttributesIcon />,
          onClick: () => navigate(EvidenceBrowserUrl.WORK_ORDER_ATTRIBUTES),
          href: EvidenceBrowserUrl.WORK_ORDER_ATTRIBUTES,
          isActive: (pathname) => {
            return [EvidenceBrowserUrl.WORK_ORDER_ATTRIBUTES].includes(
              pathname as EvidenceBrowserUrl
            );
          },
          permission: [Permission.WORK_ORDER_DYNAMIC_ATTRIBUTE_NAME_LIST],
        },
        {
          label: (
            <FormattedMessage
              id="ZSD__MENU__FILLED_FORM_ATTRIBUTES"
              defaultMessage="SAP atribúty formulárov"
            />
          ),
          icon: <EditAttributesIcon />,
          onClick: () => navigate(EvidenceBrowserUrl.FILLED_FORM_ATTRIBUTES),
          href: EvidenceBrowserUrl.FILLED_FORM_ATTRIBUTES,
          isActive: (pathname) => {
            return [EvidenceBrowserUrl.FILLED_FORM_ATTRIBUTES].includes(
              pathname as EvidenceBrowserUrl
            );
          },
          permission: [Permission.FILLED_FORM_SAP_ATTRIBUTE_LIST],
        },
        {
          label: (
            <FormattedMessage
              id="ZSD__MENU__ABSENCE_TYPES"
              defaultMessage="Typy absencií"
            />
          ),
          icon: <AlarmOffIcon />,
          onClick: () => navigate(EvidenceBrowserUrl.ABSENCE_TYPES),
          href: EvidenceBrowserUrl.ABSENCE_TYPES,
          isActive: (pathname) => {
            return [EvidenceBrowserUrl.ABSENCE_TYPES].includes(
              pathname as EvidenceBrowserUrl
            );
          },
          permission: [Permission.ABSENCE_TYPE_LIST],
        },
        {
          label: (
            <FormattedMessage id="ZSD__MENU__SPHL" defaultMessage="SPHL" />
          ),
          icon: <BookmarkIcon />,
          onClick: () => navigate(EvidenceBrowserUrl.SPHL),
          href: EvidenceBrowserUrl.SPHL,
          isActive: (pathname) => {
            return [EvidenceBrowserUrl.SPHL].includes(
              pathname as EvidenceBrowserUrl
            );
          },
          permission: [Permission.SPHL_LIST],
        },
        {
          label: (
            <FormattedMessage
              id="ZSD__MENU__METHOD_OD"
              defaultMessage="Spôsob odpojenia"
            />
          ),
          icon: <PowerIcon />,
          onClick: () => navigate(EvidenceBrowserUrl.METHOD_OD),
          href: EvidenceBrowserUrl.METHOD_OD,
          isActive: (pathname) => {
            return [EvidenceBrowserUrl.METHOD_OD].includes(
              pathname as EvidenceBrowserUrl
            );
          },
          permission: [Permission.METHOD_OD_LIST],
        },
        {
          label: (
            <FormattedMessage
              id="ZSD__MENU__DEVICE_TYPE"
              defaultMessage="Druh zariadenia"
            />
          ),
          icon: <OfflineBoltOutlinedIcon />,
          onClick: () => navigate(EvidenceBrowserUrl.DEVICE_TYPE),
          href: EvidenceBrowserUrl.DEVICE_TYPE,
          isActive: (pathname) => {
            return [EvidenceBrowserUrl.DEVICE_TYPE].includes(
              pathname as EvidenceBrowserUrl
            );
          },
          permission: [Permission.DEVICE_TYPE_LIST],
        },
        {
          label: (
            <FormattedMessage
              id="ZSD__MENU__CONTROL_TYPES"
              defaultMessage="Typy kontroly"
            />
          ),
          icon: <AssignmentTurnedInIcon />,
          onClick: () => navigate(EvidenceBrowserUrl.CONTROL_TYPES),
          href: EvidenceBrowserUrl.CONTROL_TYPES,
          isActive: (pathname) => {
            return [EvidenceBrowserUrl.CONTROL_TYPES].includes(
              pathname as EvidenceBrowserUrl
            );
          },
          permission: [Permission.CONTROL_TYPE_LIST],
        },
        {
          label: (
            <FormattedMessage
              id="ZSD__MENU__ACTIVITY_TYPES"
              defaultMessage="Typy aktivity"
            />
          ),
          icon: <TrackChangesIcon />,
          onClick: () => navigate(EvidenceBrowserUrl.ACTIVITY_TYPES),
          href: EvidenceBrowserUrl.ACTIVITY_TYPES,
          isActive: (pathname) => {
            return [EvidenceBrowserUrl.ACTIVITY_TYPES].includes(
              pathname as EvidenceBrowserUrl
            );
          },
          permission: [Permission.ACTIVITY_TYPE_LIST],
        },
        {
          label: (
            <FormattedMessage
              id="ZSD__MENU__DEFECT_TYPES"
              defaultMessage="Typy nedostatkov"
            />
          ),
          icon: <ReportProblemIcon />,
          onClick: () => navigate(EvidenceBrowserUrl.DEFECT_TYPES),
          href: EvidenceBrowserUrl.DEFECT_TYPES,
          isActive: (pathname) => {
            return [EvidenceBrowserUrl.DEFECT_TYPES].includes(
              pathname as EvidenceBrowserUrl
            );
          },
          permission: [Permission.DEFECT_TYPE_LIST],
        },
        {
          label: (
            <FormattedMessage
              id="ZSD__MENU__OPERATION_TYPES"
              defaultMessage="Typy úkonov"
            />
          ),
          icon: <BuildIcon />,
          onClick: () => navigate(EvidenceBrowserUrl.OPERATION_TYPES),
          href: EvidenceBrowserUrl.OPERATION_TYPES,
          isActive: (pathname) => {
            return [EvidenceBrowserUrl.OPERATION_TYPES].includes(
              pathname as EvidenceBrowserUrl
            );
          },
          permission: [Permission.OPERATION_TYPE_LIST],
        },
        {
          label: (
            <FormattedMessage
              id="ZSD__MENU__TECHNICAL_OBJECT_TYPES"
              defaultMessage="Typy technických objektov"
            />
          ),
          icon: <CategoryIcon />,
          onClick: () => navigate(EvidenceBrowserUrl.TECHNICAL_OBJECT_TYPES),
          href: EvidenceBrowserUrl.TECHNICAL_OBJECT_TYPES,
          isActive: (pathname) => {
            return [EvidenceBrowserUrl.TECHNICAL_OBJECT_TYPES].includes(
              pathname as EvidenceBrowserUrl
            );
          },
          permission: [Permission.TECHNICAL_OBJECT_TYPE_LIST],
        },
        {
          label: (
            <FormattedMessage
              id="ZSD__MENU__VOLTAGE_LEVELS"
              defaultMessage="Napätové úrovne"
            />
          ),
          icon: <OfflineBoltIcon />,
          onClick: () => navigate(EvidenceBrowserUrl.VOLTAGE_LEVELS),
          href: EvidenceBrowserUrl.VOLTAGE_LEVELS,
          isActive: (pathname) => {
            return [EvidenceBrowserUrl.VOLTAGE_LEVELS].includes(
              pathname as EvidenceBrowserUrl
            );
          },
          permission: [Permission.VOLTAGE_LEVEL_LIST],
        },
      ],
    },
    {
      label: (
        <FormattedMessage id="ZSD__MENU__FORMS" defaultMessage="Formuláre" />
      ),
      permission: [Permission.FORM_TEMPLATE_LIST, Permission.FILLED_FORM_LIST],
      isActive: (pathname) => {
        return !![
          EvidenceBrowserUrl.FORM_TEMPLATES,
          EvidenceBrowserUrl.FILLED_FORMS,
        ].find((url) => pathname.includes(url));
      },
      icon: <FormatShapesIcon />,
      items: [
        {
          label: (
            <FormattedMessage
              id="ZSD__MENU__FORMS__TEMPLATES"
              defaultMessage="Šablóny formulárov"
            />
          ),
          permission: [Permission.FORM_TEMPLATE_LIST],
          onClick: () => navigate(EvidenceBrowserUrl.FORM_TEMPLATES),
          href: EvidenceBrowserUrl.FORM_TEMPLATES,
          icon: <CreateIcon />,
        },
      ],
    },
    {
      label: (
        <FormattedMessage
          id="ZSD__MENU__TECHNICAL_OBJECTS"
          defaultMessage="Technické objekty"
        />
      ),
      isActive: (pathname) => {
        return [EvidenceBrowserUrl.TECHNICAL_OBJECTS].includes(
          pathname as EvidenceBrowserUrl
        );
      },
      icon: <AppsIcon />,
      onClick: () => navigate(EvidenceBrowserUrl.TECHNICAL_OBJECTS),
      href: EvidenceBrowserUrl.TECHNICAL_OBJECTS,
      permission: [Permission.TECHNICAL_OBJECT_LIST],
    },
    {
      label: (
        <FormattedMessage
          id="ZSD__MENU__MOBILE_APP"
          defaultMessage="Mobilná aplikácia"
        />
      ),
      permission: [Permission.PERMISSION_LIST],
      icon: <PhoneIphoneIcon />,
      onClick: () => {
        window.open('https://test-technik.zsdis.sk/'); // TODO move to env variable
      },
      href: 'https://test-technik.zsdis.sk/', // TODO move to env variable
    },
    {
      label: (
        <FormattedMessage
          id="ZSD__MENU__GLOBAL_SETTINGS"
          defaultMessage="Globálne nastavenia"
        />
      ),
      onClick: () => navigate(EvidenceBrowserUrl.GLOBAL_SETTINGS),
      href: EvidenceBrowserUrl.GLOBAL_SETTINGS,
      permission: [Permission.GLOBAL_SETTINGS_READ],
      isActive: (pathname) => {
        return [EvidenceBrowserUrl.GLOBAL_SETTINGS].includes(
          pathname as EvidenceBrowserUrl
        );
      },
      icon: <BuildIcon />,
    },
  ];

  const filterItems = (items: ZsdMenuItem[]) => {
    return items.filter((item) => {
      // Filter out sub items
      if (item.items) {
        item.items = filterItems(item.items);
      }

      // Hide when all sub items are filtered out (excludes separators, i.e. items without label)
      if (item.items && item.items.filter((i) => i.label).length === 0) {
        return false;
      }

      // Hide when permission is not granted
      if (item.permission) {
        return item.permission.some((permission) => hasPermission(permission));
      }
      return true;
    });
  };

  const permittedItems = filterItems(items);

  return { items, filteredItems: permittedItems };
}
