import dayjs from 'dayjs';
import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from 'react';
import Menu from '@material-ui/core/Menu';
import { usePrompts } from '@eas/common-web';
import { AllDayRouteMenuItem } from '@modules/plan/work-order-menu/menu-items/all-day-route';
import { useWorkOrderDetails } from '@modules/planner/work-order-menu/work-order-details';
import { useWorkOrderExceptions } from '@composite/planner/work-order-exceptions';
import { LockWorkOrderMenuItem } from '@composite/work-order/context-menu/lock-work-order-menu-item';
import { UnplanWorkOrderMenuItem } from '@composite/work-order/context-menu/unplan-work-order-menu-item';
import { WorkOrder } from '@models';
import { PlannerContext } from '../planner-context';
import { DetailsMenuItem } from './menu-items/details';
import { ErrorsMenuItem } from './menu-items/errors';
import { OpenMenuItem } from './menu-items/open';
import { RouteMenuItem, getUnitStartingPoint } from './menu-items/route';
import { ShowInCalendar } from './menu-items/show-in-calendar';
import { StateMenuItem } from './menu-items/state';
import { useTravelTo } from './work-order-travel-to';

type WorkOrderMenuProps = {
  previousWorkOrder?: WorkOrder;
  nextWorkOrder?: WorkOrder;
  workOrder: WorkOrder;
  stickToPointer?: boolean;
  allDayRouteOption?: boolean;
  showInCalendarOption?: boolean;
};

export type WorkOrderMenuHandle = {
  open: (event: React.MouseEvent<HTMLElement>) => void;
};

export const WorkOrderMenu = forwardRef(function WorkOrderMenu(
  {
    previousWorkOrder,
    nextWorkOrder,
    workOrder,
    stickToPointer,
    allDayRouteOption,
    showInCalendarOption,
  }: WorkOrderMenuProps,
  ref: React.Ref<WorkOrderMenuHandle>
) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const closeMenu = () => setAnchorEl(null);

  const [left, setLeft] = useState(0);

  const { calendarSource, workOrderTableRef } = useContext(PlannerContext);

  useImperativeHandle(ref, () => ({
    open: (event: React.MouseEvent<HTMLElement>) => {
      if (stickToPointer) {
        setLeft(event.nativeEvent.clientX);
      }
      setAnchorEl(event.currentTarget);
    },
  }));

  useWorkOrderDetails({
    id: workOrder.id,
    showConfirm: false,
  });

  useWorkOrderExceptions({
    id: workOrder.id,
    exceptions: workOrder.validationErrors ?? [],
    showConfirm: false,
    showText: false,
  });

  useTravelTo({
    id: workOrder.id,
    workOrders: [previousWorkOrder, workOrder],
    unitStartingPoint: getUnitStartingPoint(workOrder),
    addStartingPoint: previousWorkOrder === undefined,
    addReturnPoint: nextWorkOrder === undefined,
  });

  usePrompts([
    {
      key: `CHANGE_STATE_ALERT_${workOrder.id}`,
      dialogTitle: 'Manuálna zmena stavu',
      dialogShowClose: true,
      dialogShowConfirm: true,
      dialogWidth: 400,
      dialogText: 'Naozaj chcete zmeniť stav PP?',
    },
  ]);

  usePrompts(
    [
      {
        key: `WORK_ORDER_LOCK_ALERT_${workOrder.id}`,
        dialogTitle: 'Zamknúť / odomknúť pracovný príkaz',
        dialogShowClose: true,
        dialogShowConfirm: true,
        dialogWidth: 400,
        dialogText: (
          <div>
            Naozaj chcete <b>{workOrder.locked ? 'odomknúť' : 'zamknúť'}</b>{' '}
            pracovný príkaz?
          </div>
        ),
      },
    ],
    [workOrder.locked]
  );

  usePrompts(
    [
      {
        key: `WORK_ORDER_UNPLAN_ALERT`,
        dialogTitle: 'Odplánovať pracovný príkaz',
        dialogShowClose: true,
        dialogShowConfirm: true,
        dialogWidth: 400,
        dialogText: (
          <div>
            Naozaj chcete <b>odplánovať</b> pracovný príkaz?
          </div>
        ),
      },
    ],
    []
  );

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => {
        setAnchorEl(null);
      }}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: left === 0 ? 'right' : left,
      }}
    >
      <DetailsMenuItem closeMenu={closeMenu} workOrder={workOrder} />
      <OpenMenuItem closeMenu={closeMenu} workOrder={workOrder} />
      <ErrorsMenuItem closeMenu={closeMenu} workOrder={workOrder} />
      {showInCalendarOption &&
        workOrder.currentState?.code &&
        workOrder.currentState.code !== 'NOV' &&
        workOrder.currentState.code !== 'ZRU' && (
          <ShowInCalendar closeMenu={closeMenu} workOrder={workOrder} />
        )}
      <RouteMenuItem
        closeMenu={closeMenu}
        workOrder={workOrder}
        previousWorkOrder={previousWorkOrder}
      />
      {allDayRouteOption && workOrder.startTime && (
        <AllDayRouteMenuItem
          closeMenu={closeMenu}
          date={dayjs(workOrder.startTime).format('YYYY-MM-DD')}
          unitId={workOrder.user?.id ?? workOrder.mobileUnit?.id ?? ''}
          startingPoint={getUnitStartingPoint(workOrder)}
        />
      )}
      <LockWorkOrderMenuItem
        closeMenu={closeMenu}
        workOrder={workOrder}
        afterSubmit={() => {
          calendarSource?.yAxis.refreshData();
          workOrderTableRef.current?.refresh();
        }}
      />
      <UnplanWorkOrderMenuItem
        closeMenu={closeMenu}
        workOrder={workOrder}
        afterSubmit={() => {
          calendarSource?.yAxis.refreshData();
          workOrderTableRef.current?.refresh();
        }}
      />
      <StateMenuItem closeMenu={closeMenu} workOrder={workOrder} />
    </Menu>
  );
});
