import L from 'leaflet';
import { useCallback, useEffect, useRef, useState } from 'react';
import { MAP_TLAYER_OPACITY } from '../container/constants';
import { BasicTileLayer } from '../tile-layers/basic-tile-layer';
import { EmptyTileLayer } from '../tile-layers/empty-tile-layer';
import { Orthophoto } from '../tile-layers/orthophoto-tile-layer';

const tileLayers = [BasicTileLayer, Orthophoto, EmptyTileLayer];

export const useMapTileLayerControl = () => {
  const ref = useRef<L.TileLayer>(null);
  const [layerOpacity, setLayerOpacity] = useState(
    MAP_TLAYER_OPACITY.fullOpacity
  );
  const [currentTileLayerIndex, setCurrentTileLayerIndex] = useState(0);

  const nextTileLayer = useCallback(() => {
    setCurrentTileLayerIndex((p) => (p + 1) % tileLayers.length);
  }, []);

  useEffect(() => {
    ref.current?.setOpacity?.(layerOpacity);
  }, [layerOpacity, currentTileLayerIndex]);

  const switchContrast = useCallback(() => {
    setLayerOpacity((p) => {
      const opacity =
        p >= MAP_TLAYER_OPACITY.fullOpacity
          ? MAP_TLAYER_OPACITY.halfOpacity
          : MAP_TLAYER_OPACITY.fullOpacity;
      ref.current?.setOpacity(opacity);
      return opacity;
    });
  }, []);

  return {
    TileLayer: tileLayers?.[currentTileLayerIndex] ?? BasicTileLayer,
    nextTileLayer,
    tileLayerRef: ref,
    switchContrast,
    layerOpacity,
  };
};
