import { useContext } from 'react';
import {
  PromptContext,
  SnackbarContext,
  SnackbarVariant,
  UserSettingsContext,
} from '@eas/common-web';
import { CalendarHandle } from '../calendar/calendar-types';
import { CalendarSettings, settingsId, settingsVersion } from './settings';

export function useSettingsSubmit({
  calendarRef,
}: {
  calendarRef: React.RefObject<CalendarHandle>;
}) {
  const { testPrompt } = useContext(PromptContext);
  const { setCustomSettings } = useContext(UserSettingsContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const handleClick = () =>
    testPrompt({
      key: 'PLAN_SETTINGS_DIALOG',
      submitCallback: async (values: Partial<CalendarSettings>) => {
        const newSettings: Partial<CalendarSettings> = {
          stepWidth: values.stepWidth,
          startingHour: values.startingHour,
          endingHour: values.endingHour,
          colorForShiftV: values.colorForShiftV,
          colorForShiftZ: values.colorForShiftZ,
          colorForShiftZP: values.colorForShiftZP,
          colorForShiftA: values.colorForShiftA,
          colorForShiftP: values.colorForShiftP,
          groupToMultitaskCount: values.groupToMultitaskCount,
        };

        setCustomSettings(settingsId, {
          ...calendarRef.current?.settings,
          ...newSettings,
          version: settingsVersion,
        });

        calendarRef.current?.setSettings((oldSettings) => {
          return {
            ...oldSettings,
            ...newSettings,
          };
        });

        showSnackbar(
          'Nastavenia boli úspešne uložené',
          SnackbarVariant.SUCCESS
        );
      },
    });

  return { handleClick };
}
