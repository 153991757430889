import React, { useContext, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import {
  ApiFilterOperation,
  Autocomplete,
  TableContext,
  TableFilter,
  TextFieldMultiple,
} from '@eas/common-web';
import { useDepartmentAutocomplete } from '@modules/department/department-api';
import { DepartmentAutocomplete, DictionaryAutocomplete } from '@models';
import { labelMapper } from '@utils';

export function WorkOrderTableCustomSearchBar() {
  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      justifyContent="flex-end"
    >
      <WorkOrderIdField />
      <DepartmentFilterField />
    </Box>
  );
}

const WorkOrderIdField = () => {
  const { advancedFilters } = useContext(TableContext);
  const [selected, setSelected] = useState<number[]>(
    advancedFilters?.find?.((f) => f.field === 'workOrderId')?.values ?? []
  );
  const [value, setNewValue] = useState<number | null>(null);

  const table = useContext(TableContext);

  return (
    <Box
      style={{ width: '50%', maxWidth: 500, paddingRight: 8, minWidth: 400 }}
    >
      <Typography style={{ lineHeight: 0.8, fontSize: 'small' }}>
        Číslo zákazky:
      </Typography>
      <Box display="flex" alignItems="flex-end" width="100%">
        <TextFieldMultiple
          type="number"
          onChange={(value) => setSelected(value?.map((v) => Number(v)) ?? [])}
          value={selected.map((v) => String(v))}
        />

        <Box display="flex" alignItems="center" ml={3}>
          <Button
            disabled={selected.length === 0}
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              const currentSelection = selected;
              if (value !== null && !selected.includes(value)) {
                setSelected((p) => [...p, value]);
                setNewValue(null);
                currentSelection.push(value);
              }
              if (currentSelection.length > 0) {
                table?.setAdvancedFilters([
                  {
                    operation: ApiFilterOperation.IN,
                    field: 'workOrderId',
                    values: currentSelection,
                  },
                ]);
              } else {
                table?.setAdvancedFilters([]);
              }
            }}
          >
            <SearchIcon fontSize="small" />
          </Button>
          <Button
            disabled={selected.length === 0}
            size="small"
            onClick={() => {
              table?.setAdvancedFilters([]);
              setSelected([]);
            }}
          >
            <ClearIcon fontSize="small" />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const getFilterIndex = (filterKey: string, filters: TableFilter[]) => {
  return filters?.findIndex((f) => f.filterkey === filterKey);
};

function DepartmentFilterField() {
  const { filters, setFiltersState, filtersState } = useContext(TableContext);
  const [values, setValues] = useState<DictionaryAutocomplete[] | null>(
    filtersState[getFilterIndex('department.id', filters)]?.object ?? null
  );

  const source = useDepartmentAutocomplete();

  return (
    <Box
      style={{ width: '50%', maxWidth: 600, paddingRight: 8, minWidth: 500 }}
    >
      <Typography style={{ lineHeight: 0.8, fontSize: 'small' }}>
        Pracovisko:
      </Typography>
      <Box display="flex" alignItems="center">
        <Autocomplete<DepartmentAutocomplete>
          source={source}
          endAdornment={<></>}
          labelMapper={labelMapper}
          onChange={async (values) => {
            setValues(values as DictionaryAutocomplete[]);
          }}
          value={values}
          multiple={true}
          clearable={false}
        />
        <Box display="flex" alignItems="center" ml={3}>
          <Button
            variant="contained"
            disabled={!values || values.length === 0}
            color="primary"
            onClick={() => {
              const index = getFilterIndex('department.id', filters);
              if (index === -1) {
                return;
              }
              if (values?.length === 0) {
                setFiltersState?.((p) => {
                  const newState = [...p];
                  newState[index] = {
                    ...newState[index],
                    enabled: false,
                    object: null,
                    filters: [],
                  };
                  return newState;
                });
                return;
              }

              setFiltersState?.((p) => {
                const newState = [...p];
                newState[index] = {
                  ...newState[index],
                  enabled: true,
                  object: values,
                  filters: [
                    {
                      operation: ApiFilterOperation.IN,
                      field: 'department.id',
                      values: values?.map((v) => v.id) ?? [],
                    },
                  ],
                };
                return newState;
              });
            }}
            size="small"
          >
            <SearchIcon fontSize="small" />
          </Button>
          <Button
            disabled={!values || values.length === 0}
            size="small"
            onClick={() => {
              setValues([]);
              const index = getFilterIndex('department.id', filters);
              if (index === -1) {
                return;
              }
              setFiltersState?.((p) => {
                const newState = [...p];
                newState[index] = {
                  ...newState[index],
                  enabled: false,
                  object: null,
                  filters: [],
                };
                return newState;
              });
            }}
          >
            <ClearIcon fontSize="small" />
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
