import { useIntl } from 'react-intl';
import {
  DomainObject,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';

export function useColumns<T extends DomainObject>(): TableFieldColumn<T>[] {
  const intl = useIntl();

  return [
    {
      name: intl.formatMessage({
        id: 'ZSD__OPERATIONS__TABLE__COLUMN__TYPE_CODE',
        defaultMessage: 'Kód úkonu',
      }),
      datakey: 'type.code',
      sortkey: 'type.code',
      width: 100,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: intl.formatMessage({
        id: 'ZSD__OPERATIONS__TABLE__COLUMN__TYPE',
        defaultMessage: 'Typ',
      }),
      datakey: 'type.name',
      sortkey: 'type.name',
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },
    // {
    //   name: intl.formatMessage({
    //     id: 'ZSD__OPERATIONS__TABLE__COLUMN__PERFORMED',
    //     defaultMessage: 'Vykonaný',
    //   }),
    //   datakey: 'performed',
    //   sortkey: 'performed',
    //   width: 100,
    //   CellComponent: TableFieldCells.BooleanCell,
    // },
    {
      name: intl.formatMessage({
        id: 'ZSD__OPERATIONS__TABLE__COLUMN__DESCRIPTION',
        defaultMessage: 'Popis',
      }),
      datakey: 'description',
      sortkey: 'description',
      width: 300,
      CellComponent: TableFieldCells.TextCell,
    },
  ];
}
