import { createContext } from 'react';
import { CalendarDimensions } from '@enums';
import { CalendarContextType } from './calendar-types';

export const CalendarContext = createContext<CalendarContextType>({
  setEdgeWorkOrders: () => {},
  dataSource: {
    departmentsSource: {
      loaded: false,
      selected: [],
      setSelected: () => {},
    },
    unitsSource: {
      loaded: false,
      selected: [],
      setSelected: () => {},
    },
    yAxis: {
      data: [],
      refreshData: () => {},
    },
    holidays: [],
  },
  workOrderTableRef: { current: null },
  wrapRef: { current: null },
  settings: {
    dimension: CalendarDimensions.WEEK,
    rowHeight: 40,
    headerHeight: 75,
    legendWidth: 200,
    stepWidth: 100,
    startingHour: 0,
    endingHour: 24,
    initialScrollOffset: 0,
    colorForShiftV: '#D9D9D940',
    colorForShiftZ: '#ffffff',
    colorForShiftZP: '#e2edfd',
    colorForShiftA: '#bfe3c3',
    colorForShiftP: '#f9dddf',
    pinnedMap: false,
    groupToMultitaskCount: 15,
  },
  setSettings: () => {},
  currentDate: 0,
  setCurrentDate: () => 0,
  classes: {} as any,
});
