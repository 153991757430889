/**
 * Browser urls
 */
export enum EvidenceBrowserUrl {
  ATTENDANCE = '/zsd/dochazka',
  ABSENCE_TYPES = '/zsd/typy-absencii',
  CALENDARS = '/zsd/kalendar',
  CALENDAR_TEMPLATES = '/zsd/kalendar/sablona',
  CERTIFICATIONS = '/zsd/certifikacie',
  COMMANDS = '/zsd/prikazy',
  DATA = '/zsd/data',
  DEPARTMENTS = '/zsd/pracoviska',
  FORMS = '/zsd/formulare',
  HOLIDAYS = '/zsd/sviatky',
  HOME = '/zsd/kalendar',
  LOGIN = '/',
  LOGOUT = '/odhlasenie',
  LOGIN_ERROR = '/chyba-prihlasenia',
  MOBILE_UNITS = '/zsd/mobilne-jednotky',
  MOBILE_UNITS_SWITCH = '/zsd/mobilne-jednotky/vymena',
  PLAN_MOBILE_UNIT = '/zsd/plan/mobilna-jednotka',
  PLAN_TECHNICIAN = '/zsd/plan/technik',
  POST_OFFICES = '/zsd/provozovny-cp',
  ROLES = '/zsd/role',
  SECTIONS = '/zsd/useky',
  SHIFTS = '/zsd/zmeny',
  TEAMS = '/zsd/timy',
  USERS = '/zsd/pouzivatelia',
  WORK_ORDERS = '/zsd/pracovne-prikazy',
  WORK_ORDER_TYPES = '/zsd/druhy-pracovnych-prikazov',
  WORK_ORDER_STATES = '/zsd/stavy-pracovnych-prikazov',
  WORK_ORDER_ATTRIBUTES = '/zsd/sap-atributy-pracovnych-prikazov',
  FILLED_FORMS = '/zsd/vyplnene-formulare',
  FORM_TEMPLATES = '/zsd/sablony-formularov',
  FILLED_FORM_ATTRIBUTES = '/zsd/sap-atributy-vyplnenych-formularov',
  SPHL = '/zsd/sphl',
  TECHNICAL_OBJECTS = '/zsd/technicke-objekty',
  METHOD_OD = '/zsd/sposob-odpojenia',
  DEVICE_TYPE = '/zsd/druh-zariadenia',
  VOLTAGE_LEVELS = '/zsd/napatova-uroven',
  TECHNICAL_OBJECT_TYPES = '/zsd/typy-technickych-objektov',
  DEFECT_TYPES = '/zsd/typy-nedostatkov',
  CONTROL_TYPES = '/zsd/typy-kontroly',
  ACTIVITY_TYPES = '/zsd/typy-cinnosti',
  OPERATION_TYPES = '/zsd/typy-ukonov',
  GLOBAL_SETTINGS = '/zsd/globalne-nastavenia',
}

/**
 * API urls
 */
export enum EvidenceApiUrl {
  ABSENCES = '/api/zsd/absence',
  ABSENCE_TYPES = '/api/zsd/absence/type',
  AUTH_TYPE = '/api/zsd/auth/type',
  CALENDARS = '/api/zsd/calendar',
  CALENDAR_EXCEPTIONS = '/api/zsd/calendar-exception',
  CALENDAR_TEMPLATES = '/api/zsd/calendar-template',
  CERTIFICATIONS = '/api/zsd/certification',
  USER_CERTIFICATIONS = '/api/zsd/certification/user',
  DEPARTMENTS = '/api/zsd/department',
  HOLIDAYS = '/api/zsd/holiday',
  MOBILE_UNITS = '/api/zsd/mobile-unit',
  MOBILE_UNIT_ASSIGNMENTS = '/api/zsd/mobile-unit/assignment',
  PERMISSIONS = '/api/zsd/permission',
  ROLES = '/api/zsd/role',
  SECTIONS = '/api/zsd/section',
  SHIFTS = '/api/zsd/shift',
  TEAMS = '/api/zsd/team',
  USERS = '/api/zsd/user',
  WORK_ORDERS = '/api/zsd/work-order',
  WORK_ORDER_TYPES = '/api/zsd/work-order-type',
  WORK_ORDER_STATES = '/api/zsd/work-order/state',
  WORK_ORDER_DYNAMIC_ATTRIBUTES = '/api/zsd/dynamic-attribute',
  WORK_ORDER_DYNAMIC_ATTRIBUTE_NAMES = '/api/zsd/dynamic-attribute-name',
  FILLED_FORMS = '/api/zsd/filled-form',
  FORM_TEMPLATES = '/api/zsd/form-template',
  FORM_PRINT = '/api/zsd/form-print',
  FORM_SAVED_LAYOUT = '/api/zsd/form-layout',
  FORM_SAVED_LIBRARY = '/api/zsd/form-library',
  FILLED_FORM_ATTRIBUTES = '/api/zsd/filled-form-sap-attribute',
  NOTIFICATIONS = '/api/zsd/notification',
  NOTIFICATION_EVENTS = '/api/zsd/notification/event',
  NOTIFICATION_PREFERENCES = '/api/zsd/notification/preference',
  NOTIFICATION_RECEIVED = '/api/zsd/notification/received',
  NOTIFICATION_TEMPLATES = '/api/zsd/notification/template',
  SPHL = '/api/zsd/sphl',
  TECHNICAL_OBJECTS = '/api/zsd/technical-object',
  METHOD_OD = '/api/zsd/method-od',
  DEVICE_TYPE = '/api/zsd/device-type',
  VOLTAGE_LEVELS = '/api/zsd/voltage-level',
  TECHNICAL_OBJECT_TYPES = '/api/zsd/technical-object-type',
  DEFECTS = '/api/zsd/defect',
  DEFECT_TYPES = '/api/zsd/defect-type',
  CONTROL_TYPES = '/api/zsd/control-type',
  ACTIVITIES = '/api/zsd/activity',
  ACTIVITY_TYPES = '/api/zsd/activity-type',
  OPERATIONS = '/api/zsd/operation',
  OPERATION_TYPES = '/api/zsd/operation-type',
  GLOBAL_SETTINGS = '/api/zsd/settings',

  MISMATCH = '/api/zsd/mismatch',
}
