import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DictionaryEvidence,
  FormNumberField,
  FormPanel,
  FormTextField,
} from '@eas/common-web';

export function Fields() {
  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__ACTIVITY_TYPE__DETAIL__PANEL__GENERAL"
            defaultMessage="Všeobecné"
          />
        }
      >
        <DictionaryEvidence.FieldName />
        <FormTextField
          name="revisionUnitType"
          label={
            <FormattedMessage
              id="ZSD__ACTIVITY_TYPE__DETAIL__FIELD__REVISION_UNIT_TYPE"
              defaultMessage="Typ revízneho celku"
            />
          }
        />
        <FormTextField
          name="controlType.name"
          label={
            <FormattedMessage
              id="ZSD__ACTIVITY_TYPE__DETAIL__FIELD__CONTROL_TYPE"
              defaultMessage="Typ kontroly"
            />
          }
        />
        <FormTextField
          name="technicalObjectType.name"
          label={
            <FormattedMessage
              id="ZSD__ACTIVITY_TYPE__DETAIL__FIELD__TECHNICAL_OBJECT_TYPE"
              defaultMessage="Typ technického objektu"
            />
          }
        />
        <FormTextField
          name="technicalObjectModel"
          label={
            <FormattedMessage
              id="ZSD__ACTIVITY_TYPE__DETAIL__FIELD__TECHNICAL_OBJECT_MODEL"
              defaultMessage="Model technického objektu"
            />
          }
        />
        <FormNumberField
          name="deadline"
          label={
            <FormattedMessage
              id="ZSD__ACTIVITY_TYPE__DETAIL__FIELD__DEADLINE"
              defaultMessage="Lehota"
            />
          }
        />
        <FormNumberField
          name="deadlineShort1"
          label={
            <FormattedMessage
              id="ZSD__ACTIVITY_TYPE__DETAIL__FIELD__DEADLINE_SHORT1"
              defaultMessage="Skrátená lehota 1"
            />
          }
        />
        <FormNumberField
          name="deadlineShort2"
          label={
            <FormattedMessage
              id="ZSD__ACTIVITY_TYPE__DETAIL__FIELD__DEADLINE_SHORT2"
              defaultMessage="Skrátená lehota 2"
            />
          }
        />
        <FormNumberField
          name="deadlineShort3"
          label={
            <FormattedMessage
              id="ZSD__ACTIVITY_TYPE__DETAIL__FIELD__DEADLINE_SHORT3"
              defaultMessage="Skrátená lehota 3"
            />
          }
        />
        <FormTextField
          name="operationName"
          label={
            <FormattedMessage
              id="ZSD__ACTIVITY_TYPE__DETAIL__FIELD__OPERATION_NAME"
              defaultMessage="Názov operácie"
            />
          }
        />
        <FormTextField
          name="kst"
          label={
            <FormattedMessage
              id="ZSD__ACTIVITY_TYPE__DETAIL__FIELD__KST"
              defaultMessage="KST"
            />
          }
        />
        <FormNumberField
          name="workerCount"
          label={
            <FormattedMessage
              id="ZSD__ACTIVITY_TYPE__DETAIL__FIELD__WORKER_COUNT"
              defaultMessage="Počet pracovníkov"
            />
          }
        />
        <FormTextField
          name="responsibleDepartment.name"
          label={
            <FormattedMessage
              id="ZSD__ACTIVITY_TYPE__DETAIL__FIELD__RESPONSIBLE_DEPARTMENT"
              defaultMessage="Zodpovedné pracovisko"
            />
          }
        />
        <FormNumberField
          name="timeNorm"
          label={
            <FormattedMessage
              id="ZSD__ACTIVITY_TYPE__DETAIL__FIELD__TIME_NORM"
              defaultMessage="Časová norma"
            />
          }
        />
      </FormPanel>
    </>
  );
}
