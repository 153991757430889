import { GPSPoint } from '@models';

export const BRATISLAVA_COORDS = [17.107748, 48.148598] as [number, number];
export const CENTER_COORDS = [48.46319, 18.205448] as [number, number];
export const BRATISLAVA_COORDS_GPS_POINT: GPSPoint = {
  latitude: BRATISLAVA_COORDS[1],
  longitude: BRATISLAVA_COORDS[0],
};

export const TO_MAP_CONSTS = {
  labelsMinZoom: 17,
  labelsFontSize: 16,
  labelsFontSizeSelected: 24,

  // constant for scaling the marker on zoom, 2 == normal scaling, 1 == no scaling
  scaleSvgFactorOnZoom: 1.2,
};

export const MAP_TLAYER_OPACITY = {
  fullOpacity: 0.6,
  halfOpacity: 0.3,
};
