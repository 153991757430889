import React from 'react';
import { TechnicalObject } from '@models';
import { getTechnicalObjectType } from './oracle-types-mapper';
import TechnicalObjectPolyline from './technical-object-marker-polyline';
import TechnicalObjectSvgMarker from './technical-object-marker-svg';
import { technicalObjectHiddenList, technicalObjectSDOList } from './types';

type Props = {
  techObject: Partial<TechnicalObject>;
  isSelected?: boolean;
  onClick?:
    | ((
        e: L.LeafletMouseEvent,
        technicalObject: Partial<TechnicalObject>
      ) => void)
    | undefined;
};

export const getObjectRenderType = (techObject: Partial<TechnicalObject>) => {
  //const type = techObject?.type?.code ?? '';
  const type = getTechnicalObjectType(techObject);

  const coordinates = techObject?.coordinates ?? [];

  if (coordinates.length === 0) return 'hidden';

  if (!type) return 'unknown';

  if (technicalObjectSDOList.find((o) => o === type)) {
    // polyline, but only one coordinate
    if (coordinates?.length === 1) {
      return 'unknown';
    }
    return 'polyline';
  }

  if (technicalObjectHiddenList.find((o) => o === type)) {
    return 'hidden';
  }

  return 'svg';
};

function TechnicalObjectMapMarker({ onClick, techObject, isSelected }: Props) {
  const renderType = getObjectRenderType(techObject);

  if (renderType === 'hidden') {
    return null;
  }

  if (renderType === 'svg' || renderType === 'unknown') {
    return (
      <TechnicalObjectSvgMarker
        key={`svg-${techObject.fid}-${isSelected}`}
        techObject={techObject}
        isSelected={isSelected}
        onClick={onClick}
      />
    );
  }

  if (renderType === 'polyline') {
    return (
      <TechnicalObjectPolyline
        key={`polyline-${techObject.fid}-${isSelected}`}
        techObject={techObject}
        isSelected={isSelected}
        onClick={onClick}
      />
    );
  }

  return null;
}

export default TechnicalObjectMapMarker;
