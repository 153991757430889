import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { groupBy } from 'lodash';
import get from 'lodash/get';
import { useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { DomainObject } from '@eas/common-web';
import { useHolidayList } from '@modules/holiday/holiday-api';
import { getCalendar, getShift } from '@composite/calendar/utils';
import { Multitask } from '@composite/multitask/types';
import {
  Absence,
  Calendar,
  CalendarException,
  GPSPoint,
  Holiday,
  MobileUnit,
  WorkOrder,
  ZsdUser,
} from '@models';
import { CalendarDimensions } from '@enums';
import {
  SourceParams,
  useAbsenceList,
  useCalendarExceptionList,
  useCalendarList,
  useWorkOrderList,
} from '../plan-api';
import { getCalendarLegendRowLabel, getUnitName } from '../utils/utils';
import { PlanCalendarDataType } from './calendar-types';

dayjs.extend(duration);
interface DataSourceObject extends DomainObject {
  startingPoint?: GPSPoint;
}

function createDataSource<T extends DataSourceObject>({
  unit,
  unitType,
  nameMapper,
  workOrders,
  calendars,
  calendarExceptions,
  absences,
  holidays,
  dayOffset,
  groupToMultitaskCount = 15,
}: {
  unit: T;
  unitType: 'mobileUnit' | 'user';
  nameMapper: (unit: T, unitType: 'mobileUnit' | 'user') => string;
  workOrders: WorkOrder[];
  calendars: Calendar[];
  calendarExceptions: CalendarException[];
  absences: Absence[];
  holidays: Holiday[];
  dayOffset: number;
  dimension?: CalendarDimensions;
  groupToMultitaskCount?: number;
}): PlanCalendarDataType[] {
  const dayStart = dayjs.unix(dayOffset).startOf('day');
  const dayEnd = dayjs.unix(dayOffset).endOf('day');
  const daysCount = dayjs.unix(dayOffset).daysInMonth();

  const calendarForUnit = calendars.filter(
    (calendar) => get(calendar, `${unitType}.id`) === unit.id
  );

  const calendarExceptionsForUnit = calendarExceptions.filter(
    (calendarException) => get(calendarException, `${unitType}.id`) === unit.id
  );

  const workOrdersByDay = groupBy(
    (workOrders ?? []).filter((w) => !!w && w.currentState?.code !== 'PRE') ??
      [],
    (wo) => {
      return dayjs(wo?.startTime).format('YYYY-MM-DD');
    }
  );

  const multitasks: Multitask[] = Object.keys(workOrdersByDay)
    .filter((day) => workOrdersByDay[day].length >= groupToMultitaskCount)
    .map((day) => ({
      day,
      unitId: unit.id,
      workOrderRefs: workOrdersByDay[day].map((wo) => ({
        id: wo.id,
        workOrderId: wo.workOrderId,
        currentState: wo.currentState,
        locked: wo.locked,
      })),
    }));

  const workOrderForUnitWithoutMultitasks = workOrders.filter(
    (wo) =>
      //TODO: optimalizovat
      !multitasks.find((m) => m.workOrderRefs.map((r) => r.id).includes(wo.id))
  );

  const allowedAbsences = absences.filter((absence) => absence.allowed);

  const findShiftForDay = (offset: number) => {
    const currentDay = dayjs
      .unix(dayOffset)
      .add(offset, 'day')
      .format('YYYY-MM-DD');

    const calendar = getCalendar(calendarForUnit, currentDay);

    if (calendar) {
      const shift = getShift(calendar, currentDay);

      if (!shift) return undefined;

      const isHoliday = holidays.map((h) => h.day).includes(currentDay);
      const exception = calendarExceptionsForUnit.find((exception) =>
        dayjs(currentDay).isBetween(
          exception.validFrom,
          exception.validTo,
          'day',
          '[]'
        )
      );

      return {
        ...shift,
        isHoliday,
        isException: !!exception,
        isBlockingException: !!exception?.isBlockedForFixedTermsPlanning,
        exceptionIntervals: exception?.intervals ?? [],
      };
    }
  };

  const findMultitasksForDay = (day: string) => {
    const multitask = multitasks.find((m) => m.day === day);
    return multitask ? [multitask] : [];
  };
  const findWorkOrdersAndAbsencesForDay = (offset: number) => {
    const uDayStart = dayjs(dayStart).add(offset, 'day').unix();
    const uDayEnd = dayjs(dayEnd).add(offset, 'day').unix();
    const workOrdersForDay =
      workOrderForUnitWithoutMultitasks
        // TODO: filter by unitId is probably not needed, because of params when calling list api
        .filter((workOrder) => get(workOrder, `${unitType}.id`) === unit.id)
        .filter(({ startTime, endTime }) => {
          if (!startTime || !endTime) {
            return false;
          }
          const woStartUnix = dayjs(startTime).unix();
          const woEndUnix = dayjs(endTime).unix();
          return (
            (woStartUnix < uDayStart && woEndUnix > uDayEnd) ||
            (woStartUnix >= uDayStart && woStartUnix <= uDayEnd) ||
            (woEndUnix >= uDayStart && woEndUnix <= uDayEnd)
          );
        }) ?? [];

    const absencesForDay =
      allowedAbsences.filter(({ fromDate, toDate }) => {
        if (!fromDate || !toDate) {
          return false;
        }
        const absenceStart = dayjs(fromDate).unix();
        const absenceEnd = dayjs(toDate).unix();
        return absenceEnd > uDayStart && absenceStart < uDayEnd;
      }) ?? [];

    return { workOrdersForDay, absencesForDay };
  };

  return Array.from({ length: daysCount }).map((_, index) => {
    const shift = findShiftForDay(index);

    const overflowShift = findShiftForDay(index - 1);

    const day = dayjs.unix(dayOffset).add(index, 'day') as dayjs.Dayjs;

    const { workOrdersForDay, absencesForDay } =
      findWorkOrdersAndAbsencesForDay(index);
    const uDayStart = dayjs(dayStart).add(index, 'day').unix();

    return {
      id: `day-${index}-${day.unix()}`,
      unitType,
      multitasks: findMultitasksForDay(day.format('YYYY-MM-DD')),
      latitude: unit.startingPoint?.latitude,
      longitude: unit.startingPoint?.longitude,
      rowLabel: getCalendarLegendRowLabel(day),
      name: nameMapper(unit, unitType),
      date: uDayStart,
      overflowShift,
      shifts: [shift], // TODO: can be multiple shifts?
      absences: absencesForDay ? absencesForDay : [],
      workOrders: workOrdersForDay ? workOrdersForDay : [],
    };
  });
}

export type PlanCalendarSource = ReturnType<typeof usePlanCalendarDataSource>;

export function usePlanCalendarDataSource(
  unit: MobileUnit | ZsdUser | undefined,
  unitType: 'mobileUnit' | 'user',
  currentDay: number,
  groupToMultitaskCount?: number
) {
  const params: SourceParams = {
    unit,
    unitType,
    skip: !unit || !currentDay,
    currentDay,
  };

  const [recreate, setRecreate] = useState(0);

  const workOrderSource = useWorkOrderList(params);
  const calendarSource = useCalendarList(params);
  const calendarExceptionSource = useCalendarExceptionList(params);
  const absenceSource = useAbsenceList(params);
  const holidaySource = useHolidayList({
    currentDay,
    dimmension: CalendarDimensions.MONTH,
    skip: params.skip,
  });

  const unitData = unit
    ? createDataSource({
        unit,
        unitType,
        nameMapper: getUnitName,
        workOrders: workOrderSource?.result?.items ?? [],
        calendars: calendarSource?.result?.items ?? [],
        calendarExceptions: calendarExceptionSource?.result?.items ?? [],
        absences: absenceSource?.result?.items ?? [],
        holidays: holidaySource?.result?.items ?? [],
        dayOffset: currentDay,
        groupToMultitaskCount,
      })
    : [];

  const yAxis = useMemo(() => {
    return {
      data: unitData,
      recreateData: () => {
        setRecreate((p) => p + 1);
      },
      refreshData: () => {
        unstable_batchedUpdates(() => {
          calendarSource.reset(true);
          workOrderSource.reset(true);
          absenceSource.reset(true);
          holidaySource.reset(true);
          calendarExceptionSource.reset(true);
        });
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    unitData,
    unit,
    calendarSource,
    workOrderSource,
    absenceSource,
    holidaySource,
    calendarExceptionSource,
    recreate,
  ]);

  return {
    yAxis,
    holidays: holidaySource?.result?.items ?? [],
  };
}
