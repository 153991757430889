import { useContext } from 'react';
import {
  ApiFilterOperation,
  Filter,
  ListSource,
  Params,
  UserContext,
  useAutocompleteSource,
  useFetch,
} from '@eas/common-web';
import { Department, DepartmentAutocomplete, ZsdUser } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useDepartments(params?: Params) {
  return useAutocompleteSource<DepartmentAutocomplete>({
    url: `${EvidenceApiUrl.DEPARTMENTS}/autocomplete`,
    params,
  });
}

const formatDepartmentFilters = (
  exclude?: string,
  relevandIds: string[] = []
) => {
  const filters: Filter[] = [];
  if (exclude) {
    filters.push({
      operation: ApiFilterOperation.NOT,
      filters: [
        {
          operation: ApiFilterOperation.EQ,
          field: 'id',
          value: exclude,
        },
      ],
    });
  }
  if (relevandIds?.length > 0) {
    filters.push({
      operation: ApiFilterOperation.OR,
      filters: relevandIds.map((id) => ({
        field: 'id',
        operation: ApiFilterOperation.EQ,
        value: id,
      })),
    });
  }
  return filters;
};

export const getUserDepartmentIdsList = (user: ZsdUser): string[] => {
  const list: string[] = [];
  if (user.coordinatorPrimaryDepartment) {
    list.push(user.coordinatorPrimaryDepartment.id);
  }
  if (user.additionalDepartments) {
    user.additionalDepartments.forEach((dep) => list.push(dep.id));
  }
  return list;
};

export function useDepartmentAutocomplete(exclude?: string) {
  const { user } = useContext(UserContext);
  const ids = getUserDepartmentIdsList(user);
  return useAutocompleteSource<DepartmentAutocomplete>({
    url: `${EvidenceApiUrl.DEPARTMENTS}/autocomplete`,
    params: {
      filters: formatDepartmentFilters(exclude, ids),
    },
  });
}
export function useDepartmentList(exclude?: string) {
  const { user } = useContext(UserContext);
  const ids = getUserDepartmentIdsList(user);
  const [result, loading, reset, setLoading] = useFetch<ListSource<Department>>(
    `${EvidenceApiUrl.DEPARTMENTS}/list`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        filters: formatDepartmentFilters(exclude, ids),
        size: -1,
      }),
    }
  );

  return {
    result,
    loading,
    reset,
    setLoading,
  };
}

export function useDepartmentListByTeam(teamId: string | null) {
  const [result, loading, reset, setLoading] = useFetch<ListSource<Department>>(
    `${EvidenceApiUrl.DEPARTMENTS}/list`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...(teamId && {
          filters: [
            {
              operation: ApiFilterOperation.EQ,
              field: 'team.id',
              value: teamId,
            },
          ],
        }),
        size: -1,
      }),
      skip: !teamId,
    }
  );

  return {
    result,
    loading,
    reset,
    setLoading,
  };
}

export function useGetDepartment(id: string | null) {
  return useFetch<Department>(`${EvidenceApiUrl.DEPARTMENTS}/${id}`, {
    skip: !id,
  });
}

export function activateCalamity(departmentId: string) {
  return fetch(
    `${EvidenceApiUrl.DEPARTMENTS}/${departmentId}/calamity/activate`,
    {
      method: 'POST',
    }
  );
}

export function deactivateCalamity(departmentId: string) {
  return fetch(
    `${EvidenceApiUrl.DEPARTMENTS}/${departmentId}/calamity/deactivate`,
    {
      method: 'POST',
    }
  );
}

export const useAbsentTechniciansFromDepartment = ({
  department,
}: {
  department: string;
}) => {
  return useFetch<{ string: ZsdUser[] }>(`${EvidenceApiUrl.ABSENCES}/list`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      filters: [
        {
          operation: ApiFilterOperation.EQ,
          field: 'user.department.id',
          value: department,
        },
        // {
        //   operation: ApiFilterOperation.EQ,
        //   field: 'date',
        //   value: date,
        // },
      ],
      size: -1,
    }),
  });
};
