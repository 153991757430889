import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DictionaryEvidence, FormPanel, FormTextField } from '@eas/common-web';

export function Fields() {
  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__OPERATION_TYPE__DETAIL__PANEL__GENERAL"
            defaultMessage="Všeobecné"
          />
        }
      >
        <DictionaryEvidence.FieldName />
        <FormTextField
          name="revisionUnitType"
          label={
            <FormattedMessage
              id="ZSD__OPERATION_TYPE__DETAIL__FIELD__REVISION_UNIT_TYPE"
              defaultMessage="Typ revíznej jednotky"
            />
          }
        />
        <FormTextField
          name="controlType.name"
          label={
            <FormattedMessage
              id="ZSD__OPERATION_TYPE__DETAIL__FIELD__CONTROL_TYPE"
              defaultMessage="Typ kontroly"
            />
          }
        />
        <FormTextField
          name="technicalObjectType.name"
          label={
            <FormattedMessage
              id="ZSD__OPERATION_TYPE__DETAIL__FIELD__TECHNICAL_OBJECT_TYPE"
              defaultMessage="Typ technického objektu"
            />
          }
        />
      </FormPanel>
    </>
  );
}
