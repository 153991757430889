import cn from 'clsx';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { VariableSizeList as List } from 'react-window';
import { useScrollBarSize } from '@eas/common-web';
import { CalendarContext } from '../calendar-context';
import { getWholeWidth } from '../utils';
import { Bars } from './bars';
import { useStyles } from './chart-styles';
import { Legend } from './legend';
import { Scale } from './scale';

interface ChartProps {
  className?: string;
}

export function Chart({ className }: ChartProps) {
  const { settings, wrapRef, dataSource } = useContext(CalendarContext);

  const scaleRef = useRef<HTMLDivElement>(null);
  const legendRef = useRef<List>(null);
  const barsRef = useRef<List>(null);

  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    // we need to call recreateData if group to multitask limit changes
    dataSource.yAxis.recreateData?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.groupToMultitaskCount]);

  const scaleWidth = useMemo(() => {
    return getWholeWidth(settings);
  }, [settings]);

  const scrollbarSize = useScrollBarSize();

  useEffect(() => {
    if (scaleWidth && wrapRef?.current?.clientHeight) {
      setWidth(scaleWidth);
      setHeight(
        wrapRef.current.offsetHeight - settings.headerHeight - scrollbarSize - 2
      );
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scaleWidth]);

  return (
    <div
      className={cn(classes.wrapper, className)}
      ref={wrapRef}
      onScroll={(evt) => {
        barsRef.current?.scrollTo(evt.currentTarget.scrollTop);
        legendRef.current?.scrollTo(evt.currentTarget.scrollTop);
        scaleRef.current?.scrollTo({ left: evt.currentTarget.scrollLeft });
      }}
    >
      {loading ? (
        <div />
      ) : (
        <>
          <Scale ref={scaleRef} width={width} />
          <Legend
            ref={legendRef}
            data={dataSource.yAxis.data}
            height={height}
          />
          <Bars
            ref={barsRef}
            data={dataSource.yAxis.data}
            width={width}
            height={height}
          />
        </>
      )}
    </div>
  );
}
