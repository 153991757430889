import React, { useContext } from 'react';
import {
  DatedEvidence,
  DictionaryEvidence,
  FormAutocomplete,
  FormContext,
  FormTextField,
} from '@eas/common-web';
import { useDepartments } from '@modules/department/department-api';
import { useWorkOrderTypes } from '@modules/work-order-type/work-order-type-api';

export function Fields() {
  const workOrderTypes = useWorkOrderTypes();
  const departments = useDepartments();

  const { getFieldValues } = useContext(FormContext);
  return (
    <>
      <DictionaryEvidence.FieldName />
      <FormAutocomplete
        name="workOrderTypes"
        label="Druhy pracovných príkazov"
        source={workOrderTypes}
        multiple
      />
      <FormAutocomplete
        name="departments"
        label="Pracoviská"
        source={departments}
        multiple
      />
      {getFieldValues().muzaFormId && (
        <FormTextField name="muzaFormId" label="Muza Form ID" disabled={true} />
      )}
      <DatedEvidence.DatedFields />
    </>
  );
}
