import { TechnicalObjectOracle } from 'src/models/technical-object';
import { TechnicalObject } from '@models';
import { TechnicalObjectMarkerType } from './types';

/***
 *
 * WIP
 *
 */
export const getTechnicalObjectType = (
  technicalObject: Partial<TechnicalObject> | TechnicalObjectOracle
): TechnicalObjectMarkerType => {
  if (technicalObject.type?.code) {
    return technicalObject.type.code as TechnicalObjectMarkerType;
  }
  if ((technicalObject as TechnicalObjectOracle)?.oracleType?.id) {
    return mapOracleHubTypeToMuzaType(
      (technicalObject as TechnicalObjectOracle).oracleType!.id
    );
  }
  return TechnicalObjectMarkerType.UNKNOWN;
};

const mapOracleHubTypeToMuzaType = (id: number): TechnicalObjectMarkerType => {
  if (ORACLE_TYPES[id]) {
    return ORACLE_TYPES[id];
  }

  if (ORACLE_TYPES_EST.includes(id)) {
    return TechnicalObjectMarkerType.EST;
  }
  return TechnicalObjectMarkerType.UNKNOWN;
};

// iba 27 je tam 2x
const ORACLE_TYPES_EST = [
  27, 27, 100, 63, 8, 82, 83, 84, 85, 118, 32, 89, 17, 68, 141, 143, 142, 31,
  33, 34, 77, 30, 42, 48, 47, 156, 166, 81, 117, 154, 152, 64, 167, 67, 144,
  145, 146, 15, 76, 53, 148, 150, 96, 88, 26, 90, 91, 165, 29, 66, 35, 36, 147,
  140, 138, 149, 97, 75, 1, 3, 73, 71, 155, 153, 80, 54, 19, 21, 92, 93, 70, 23,
  157, 158, 49, 6, 124, 125, 116, 98, 94, 95, 114, 126, 22, 24,
];

const ORACLE_TYPES: Record<number, TechnicalObjectMarkerType> = {
  346: TechnicalObjectMarkerType.VVN_USEK_VEDENIA,

  304: TechnicalObjectMarkerType.VVN_USEK_VEDENIA,
  305: TechnicalObjectMarkerType.VN_USEK_VEDENIA,
  306: TechnicalObjectMarkerType.NN_USEK_VEDENIA,

  323: TechnicalObjectMarkerType.EST,

  347: TechnicalObjectMarkerType.VN_USEK_VEDENIA,

  324: TechnicalObjectMarkerType.NN_SKRINA,

  328: TechnicalObjectMarkerType.TS_UZEMNENIE, // ?? je tam zemnenie
  331: TechnicalObjectMarkerType.TS_UZEMNENIE,

  1080: TechnicalObjectMarkerType.NN_MIV,
};

/*
const ZVYSNE = [
  250, 346, 347, 213, 304, 305, 323, 323, 323, 323, 323, 323, 323, 323, 323,
  850, 850, 850, 850, 871, 871, 871, 856, 856, 856, 856, 321, 321, 321, 321,
  870, 870, 870, 870, 870, 870, 870, 852, 852, 852, 852, 857, 857, 857, 863,
  863, 863, 864, 864, 864, 864, 865, 865, 865, 865, 851, 851, 851, 851, 854,
  854, 854, 862, 862, 862, 861, 861, 861, 861, 858, 858, 858, 349, 349, 349,
  348, 348, 348, 354, 354, 354, 315, 315, 314, 314, 309, 309, 308, 308, 308,
  304, 304, 306, 306, 306, 305, 305, 320, 320, 320, 320, 320, 324, 324, 324,
  324, 324, 324, 324, 331, 331, 328, 328, 1080, 1080, 213, 213, 311, 311, 311,
  318, 318, 318, 318, 318, 317, 317, 317, 317, 317, 317, 317,
];*/
