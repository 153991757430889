import React from 'react';
import * as ReactLeaflet from 'react-leaflet';
import { MAP_TLAYER_OPACITY } from '../container/constants';

export const Orthophoto = React.forwardRef<L.TileLayer, any>(
  function Orthophoto(_, ref) {
    return (
      <ReactLeaflet.TileLayer
        ref={ref}
        url={`https://zbgis.skgeodesy.sk/zbgis/rest/services/Ortofoto/MapServer/tile/{z}/{y}/{x}`}
        attribution='&copy; <a href="https://www.geoportal.sk/sk/geoportal.html">GKÚ, NLC</a>'
        minZoom={8}
        maxZoom={24}
        maxNativeZoom={19}
        crossOrigin={true}
        tileSize={256}
        opacity={MAP_TLAYER_OPACITY.fullOpacity}
      />
    );
  }
);
