import cn from 'clsx';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { VariableSizeList as List } from 'react-window';
import { PlannerContext } from '@modules/planner/planner-context';
import { useUnitsDayPlans } from '@modules/planner/unit-menu/unit-plan';
import { TableRowHandlersProvider } from '@composite/table/table-row-handlers-context';
import { WorkOrder } from '@models';
import { CalendarContext } from '../calendar-context';
import { getInitialScrollOffset, getWholeWidth } from '../utils';
import { Bars } from './bars';
import { useStyles } from './chart-styles';
import { Legend } from './legend';
import { Now } from './now';
import { Scale } from './scale';

interface ChartProps {
  className?: string;
}

export function Chart({ className }: ChartProps) {
  const { settings, wrapRef, dataSource, currentDate } =
    useContext(CalendarContext);

  const { highlightedWorkOrder, setHighlightedWorkOrder } =
    useContext(PlannerContext);

  const scaleRef = useRef<HTMLDivElement>(null);
  const legendRef = useRef<List>(null);
  const barsRef = useRef<List>(null);

  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [width, setWidth] = useState(0);

  useUnitsDayPlans({ data: dataSource.yAxis.data });

  const scaleWidth = useMemo(() => {
    return getWholeWidth(currentDate, settings);
  }, [currentDate, settings]);

  useEffect(() => {
    // we need to call recreateData if group to multitask limit changes
    dataSource.yAxis.recreateData?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.groupToMultitaskCount]);

  useEffect(() => {
    if (scaleWidth && wrapRef?.current?.clientHeight) {
      setWidth(scaleWidth);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scaleWidth]);

  useEffect(() => {
    if (!loading && wrapRef.current) {
      wrapRef.current.scrollLeft = settings.initialScrollOffset;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (!loading && wrapRef.current && highlightedWorkOrder) {
      const offset = dataSource.yAxis.data.findIndex((d) => {
        return (
          (d?.workOrders?.findIndex((w) => w.id === highlightedWorkOrder?.id) ??
            -1) > -1
        );
      });
      if (offset > -1) {
        const start = (highlightedWorkOrder as WorkOrder).startTime;
        const scrl = getInitialScrollOffset(
          dayjs(start).startOf('day').unix(),
          settings,
          dayjs(start).unix()
        );
        wrapRef.current?.scrollTo({
          top: offset * settings.rowHeight,
          left: scrl,
        });
        setHighlightedWorkOrder(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, dataSource.yAxis.data, settings, highlightedWorkOrder]);

  return (
    <div
      className={cn(classes.wrapper, className)}
      ref={wrapRef}
      onScroll={(evt) => {
        barsRef.current?.scrollTo(evt.currentTarget.scrollTop);
        legendRef.current?.scrollTo(evt.currentTarget.scrollTop);
        scaleRef.current?.scrollTo({ left: evt.currentTarget.scrollLeft });
      }}
    >
      {loading ? (
        <div />
      ) : (
        <>
          <Now data={dataSource.yAxis.data} />
          <Scale ref={scaleRef} width={width} />
          <Legend ref={legendRef} data={dataSource.yAxis.data} />
          <TableRowHandlersProvider rows={dataSource.yAxis.data}>
            <Bars ref={barsRef} data={dataSource.yAxis.data} width={width} />
          </TableRowHandlersProvider>
        </>
      )}
    </div>
  );
}
