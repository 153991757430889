import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import { PromptContext, usePrompts } from '@eas/common-web';
import { CalendarContext } from '@modules/plan/calendar/calendar-context';
import { getTaskBoundaries } from '@modules/plan/calendar/utils';
import { getTaskStart } from '@modules/plan/calendar/utils/get-task-start';
import { getTaskWidth } from '@modules/plan/calendar/utils/get-task-width';
import { MultitaskDetailDialog } from '@composite/multitask/multitask-detail-dialog';
import { useMultitaskStyles } from '@composite/multitask/multitask-styles';
import { Multitask } from '@composite/multitask/types';
import { WorkOrder } from '@models';

dayjs.extend(duration);

const getMultitaskKey = (multitask: Multitask) =>
  `MULTITASK_${multitask.day}_${multitask.unitId}`;

export function MultiTask({
  multitask,
  unitName,
}: {
  multitask: Multitask;
  unitName: string;
}) {
  const classes = useMultitaskStyles();
  const { settings, dataSource } = useContext(CalendarContext);
  const { testPrompt } = useContext(PromptContext);

  const { workOrderStart, workOrderEnd } = getTaskBoundaries(
    dayjs(multitask.day).unix(),
    settings,
    {
      startTime: `${multitask.day}T00:01`,
      endTime: `${multitask.day}T23:58`,
    } as WorkOrder
  );

  const taskSize = getTaskWidth(workOrderStart, workOrderEnd, 0, settings);

  usePrompts(
    [
      {
        key: getMultitaskKey(multitask),
        dialogTitle: `Množina úloh - ${unitName} (${dayjs(multitask.day).format(
          'DD.MM.YYYY'
        )} )`,
        dialogShowConfirm: false,
        dialogWidth: 900,
        dialogText: (
          <MultitaskDetailDialog
            unitName={unitName}
            multitask={multitask}
            refetch={() => {
              dataSource?.yAxis?.refreshData?.();
            }}
          />
        ),
      },
    ],
    [multitask, dataSource.yAxis.refreshData]
  );

  if (taskSize === 0) {
    return null;
  }

  const taskStart = getTaskStart(
    dayjs(multitask.day).unix(),
    workOrderStart,
    settings
  );

  return (
    <div
      onClick={() => {
        testPrompt({
          key: getMultitaskKey(multitask),
          submitCallback: () => {},
        });
      }}
      className={classes.root}
      style={{
        left: taskStart,
        width: taskSize,
        height: 30 / 1,
        top: 0,
      }}
    >
      <div className={classes.content}>
        <div className={classes.bullet} />
        <Typography variant="body2" className={classes.title}>
          Množina úloh (<b>{multitask?.workOrderRefs?.length} </b>pracovných
          príkazov)
        </Typography>
      </div>
    </div>
  );
}
