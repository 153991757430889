import { useFetch } from '@eas/common-web';
import { TechnicalObject } from '@models';
import { EvidenceApiUrl } from '@enums';

export const useTechnicalObjectsList = (workOrderId: string) => {
  return useFetch<TechnicalObject[]>(
    `${EvidenceApiUrl.TECHNICAL_OBJECTS}/list/${workOrderId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};
